import { trigger } from '../utilities/trigger';

export default class Intro {
	/**
	 * @param {string} element - Selector
	 * @param {Object} options - User options
	 */
	constructor(element, options) {
		this.element = element;
		this.DOM = {
			html: document.documentElement,
			body: document.body,
			element: document.querySelector(this.element),
			loader: document.querySelector('#js-loader'),
		};
		this.introSkipped = false;
		this.lines = Array.from(this.DOM.element.children);
		this.counter = 0;

		this.initEvents();
	}

	initEvents() {
		window.addEventListener('ENTER_SCENE', this.fireAnimation.bind(this));
		window.addEventListener('INTRO_FINISHED', this.closeLoader.bind(this));
		document.addEventListener('click', this.handleClick.bind(this), false);
	}

	handleClick(event) {
		if (!event.target.matches('.js-skip')) return;
		this.introSkipped = true;
		trigger('INTRO_SKIPPED');
		trigger('INTRO_FINISHED');
		this.closeLoader();
	}

	fireAnimation() {
		this.DOM.body.classList.add('is-intro-ready');
		setTimeout(() => {
			this.DOM.body.classList.add('is-intro-start');
			this.setCurrentLine();
			this.textInterval = setInterval(this.setCurrentLine.bind(this), 980);
		}, 1150);
	}

	setCurrentLine() {
		if (this.introSkipped) {
			this.stopTextInterval();
			return;
		}

		const currentLine = this.lines[this.counter];
		if (currentLine) {
			currentLine.classList.add('is-active');
			const translateY = -currentLine.offsetHeight * this.counter;
			this.DOM.element.style.transform = `translateY(${translateY}px)`;
		}
		this.counter++;
		if (this.counter >= this.lines.length) {
			const finalTranslateY = -currentLine.offsetHeight * (this.counter + 1);
			this.DOM.element.style.transform = `translateY(${finalTranslateY}px)`;
			trigger('INTRO_FINISHED');
			this.stopTextInterval();
		}
	}

	stopTextInterval() {
		clearInterval(this.textInterval);
	}

	closeLoader() {
		this.DOM.body.classList.add('is-intro-ended');
		setTimeout(() => {
			if (this.DOM.loader) {
				this.DOM.loader.remove();
			}
		}, 4000);
	}
}
