import StatsJs from 'stats.js';

export default class Stats {
	constructor(active) {
		this.stats = new StatsJs();
		this.active = active || false;

		if (active) {
			this.init();
		}
	}

	init() {
		this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
		document.body.appendChild(this.stats.dom);
	}

	begin() {
		if (!this.active) {
			return;
		}
		this.stats.begin();
	}

	end() {
		if (!this.active) {
			return;
		}
		this.stats.end();
	}

	update() {
		if (!this.active) {
			return;
		}
		this.stats.update();
	}

	deactivate() {
		this.active = false;
		document.body.removeChild(this.stats.dom);
	}

	destroy() {
		this.deactivate();
	}
}
