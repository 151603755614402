// Simplex 4D Noise functions and variables
const noise = `
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
float permute(float x){return floor(mod(((x*34.0)+1.0)*x, 289.0));}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
float taylorInvSqrt(float r){return 1.79284291400159 - 0.85373472095314 * r;}

vec4 grad4(float j, vec4 ip){
  const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
  vec4 p,s;

  p.xyz = floor(fract(vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
  p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
  s = vec4(lessThan(p, vec4(0.0)));
  p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;

  return p;
}

float snoise(vec4 v){
  const vec2  C = vec2( 0.138196601125010504,  // (5 - sqrt(5))/20  G4
                        0.309016994374947451); // (sqrt(5) - 1)/4   F4
  vec4 i  = floor(v + dot(v, C.yyyy) );
  vec4 x0 = v -   i + dot(i, C.xxxx);

  vec4 i0;
  vec3 isX = step( x0.yzw, x0.xxx );
  vec3 isYZ = step( x0.zww, x0.yyz );

  i0.x = isX.x + isX.y + isX.z;
  i0.yzw = 1.0 - isX;
  i0.y += isYZ.x + isYZ.y;
  i0.zw += 1.0 - isYZ.xy;
  i0.z += isYZ.z;
  i0.w += 1.0 - isYZ.z;

  vec4 i3 = clamp( i0, 0.0, 1.0 );
  vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
  vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );

  vec4 x1 = x0 - i1 + 1.0 * C.xxxx;
  vec4 x2 = x0 - i2 + 2.0 * C.xxxx;
  vec4 x3 = x0 - i3 + 3.0 * C.xxxx;
  vec4 x4 = x0 - 1.0 + 4.0 * C.xxxx;

  i = mod(i, 289.0);
  float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
  vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));

  vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;

  vec4 p0 = grad4(j0,   ip);
  vec4 p1 = grad4(j1.x, ip);
  vec4 p2 = grad4(j1.y, ip);
  vec4 p3 = grad4(j1.z, ip);
  vec4 p4 = grad4(j1.w, ip);

  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;
  p4 *= taylorInvSqrt(dot(p4,p4));

  vec3 m0 = max(0.6 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0);
  vec2 m1 = max(0.6 - vec2(dot(x3,x3), dot(x4,x4)            ), 0.0);
  m0 = m0 * m0;
  m1 = m1 * m1;
  return 49.0 * ( dot(m0*m0, vec3( dot( p0, x0 ), dot( p1, x1 ), dot( p2, x2 )))
               + dot(m1*m1, vec2( dot( p3, x3 ), dot( p4, x4 ) ) ) ) ;
}
`;

import * as THREE from 'three';

const PI = Math.PI;
const PI2 = Math.PI * 2;

class Particles extends THREE.Points {
  constructor(gu) {
    const particlePos = [];
    const particleAlpha = [];
    const numParticles = 500;

    for (let i = 0; i < numParticles; i++) {
      particlePos.push(
        Math.random() * 0.2 + 0.2,
        Math.random() + 1,
        Math.random(),
        0,
      );

      particleAlpha.push(Math.random(), Math.random() * 0.4 + 0.1, 0, 0);
    }

    const g = new THREE.BufferGeometry().setFromPoints(
      new Array(numParticles).fill().map(() => {
        const r = 2;
        return new THREE.Vector3().setFromCylindricalCoords(
          Math.sqrt(r * r * Math.random()),
          Math.random() * PI2,
          Math.random() * 10,
        );
      })
    );

    g.setAttribute('particlePos', new THREE.Float32BufferAttribute(particlePos, 4));
    g.setAttribute('particleAlpha', new THREE.Float32BufferAttribute(particleAlpha, 4));

    const m = new THREE.PointsMaterial({
      size: 0.08,
      color: 'gold',
      transparent: true,
      depthTest: false,
      blending: THREE.AdditiveBlending,
      onBeforeCompile: shader => {
        shader.uniforms.time = gu.time;
        shader.uniforms.heightLimMin = { value: 0 };
        shader.uniforms.heightLimMax = { value: 10 };

        shader.vertexShader = `
          uniform float time;
          uniform float heightLimMin;
          uniform float heightLimMax;
          attribute vec4 particlePos;
          attribute vec4 particleAlpha;
          varying float vParticleAlpha;

          mat2 rot(float a) {
            float c = cos(a);
            float s = sin(a);
            return mat2(c, s, -s, c);
          }

          ${noise}
          ${shader.vertexShader}
        `
          .replace(
            `#include <begin_vertex>`,
            `#include <begin_vertex>

          float t = time;
          vParticleAlpha = sin(mod((particleAlpha.x + t * particleAlpha.y) * PI2, PI2)) * 0.5 + 0.5;

          float hGap = heightLimMax - heightLimMin;
          float halfGap = hGap * 0.5;

          float verticalSpeed = particlePos.x;
          float verticalShift = mod((position.y - heightLimMin) + verticalSpeed * t, hGap);
          transformed.y = heightLimMin + verticalShift;
          float verticalFade = smoothstep(halfGap, halfGap - 1., abs(verticalShift - halfGap));
          vParticleAlpha *= verticalFade;

          float n = snoise(vec4(position, t * 0.05));
          float radius = particlePos.y;
          float phase = particlePos.z;
          float angle = (phase + n) * PI;
          vec2 shiftVec = rot(angle) * vec2(radius, 0.);

          transformed.xz += shiftVec;

        `
          )
          .replace(
            `gl_PointSize = size;`,
            `gl_PointSize = size * vParticleAlpha;`,
          );

        shader.fragmentShader = `
          varying float vParticleAlpha;
          ${shader.fragmentShader}
        `
          .replace(
            `#include <color_fragment>`,
            `#include <color_fragment>

          vec2 uv = gl_PointCoord.xy - 0.5;
          float d = sqrt(dot(uv, uv));

          if (d > 0.5) discard;

          float f = smoothstep(0.5, 0., d);
          f *= f * f * f * f;
          f = f * 0.95 + 0.05;
          diffuseColor.a = vParticleAlpha * f;
        `,
          );
      },
    });

    super(g, m);
  }
}

export default Particles;