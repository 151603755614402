/**
 * Panel module
 */
// import { initObserver } from '../utilities/observer';
const Deepmerge = require('deepmerge');
import { trigger } from '../utilities/trigger';

export default class Panel {
	/**
	 * @param {string} element - Selector
	 * @param {Object} options - User options
	 */
	constructor(element, options) {
		this.element = element || '.js-panel';
		this.defaults = {
			triggers: this.element
		};
		this.DOM = {
			html: document.documentElement,
			body: document.body
		};
		this.currentObject;
		this.options = options ? Deepmerge(this.defaults, options) : this.defaults;
		this.initEvents();
	}

	initEvents() {
		window.addEventListener('clickObject', (e) => this.initPanel(e));
	}

	initPanel(e) {
		this.DOM.panelTitle = document.querySelector('.js-panel-title');
		this.DOM.panelDescription = document.querySelector('.js-panel-description');
		this.DOM.panelImage = document.querySelector('.js-panel-image');
		this.DOM.panelButtonBuy = document.querySelector('.js-panel-button-buy');

		// Bottoni AR
		this.DOM.arButton = document.querySelector('.js-ar-button');
		this.DOM.quicklookButton = document.querySelector('.js-quicklook-button');

		// Petals color
		this.DOM.colorsContainer = document.querySelector('.js-panel-colors');
		this.DOM.dotsPetals = document.querySelector('.js-dots-petals');

		// Box color
		this.DOM.boxContainer = document.querySelector('.js-panel-box');
		this.DOM.dotsBox = document.querySelector('.js-dots-box');

		this.DOM.buyButtonLink = document.querySelector('.js-panel-button-buy a');

		if (e.detail.action === 'object-in') {
			this.populateConfiguratorData(e);
			this.currentObject = e.detail.object;

			this.DOM.panelTitle.innerHTML = this.currentObject.name;
			this.DOM.panelDescription.innerHTML = this.currentObject.description;

			this.updateImage();
			this.updateLink();
			this.updateARButtons(); // Aggiorna i bottoni AR

			// Applica l'animazione in entrata con ritardo
			setTimeout(() => {
				this.DOM.panelTitle.classList.add('opacity-100', 'top-0');
				this.DOM.panelDescription.classList.add('opacity-100', 'top-0');
				this.DOM.panelImage.classList.add('opacity-100', 'top-0');
				this.DOM.panelButtonBuy.classList.add('!bottom-0');
				this.DOM.colorsContainer.classList.add('opacity-100', 'top-0');
				this.DOM.boxContainer.classList.add('opacity-100', 'top-0');
				this.DOM.dotsBox.classList.add('opacity-100', 'top-0');
				this.DOM.dotsPetals.classList.add('opacity-100', 'top-0');
			}, 150);
		} else if (e.detail.action === 'object-out') {
			// Applica l'animazione in uscita con ritardo
			setTimeout(() => {
				this.DOM.panelTitle.innerHTML = '';
				this.DOM.panelDescription.innerHTML = '';
				this.DOM.panelImage.src = '';

				// Rimuovi le classi di animazione
				this.DOM.panelTitle.classList.remove('opacity-100', 'top-0');
				this.DOM.panelDescription.classList.remove('opacity-100', 'top-0');
				this.DOM.panelImage.classList.remove('opacity-100', 'top-0');
				this.DOM.panelButtonBuy.classList.remove('!bottom-0');
				this.DOM.colorsContainer.classList.remove('opacity-100', 'top-0');
				this.DOM.boxContainer.classList.remove('opacity-100', 'top-0');
				this.DOM.dotsBox.classList.remove('opacity-100', 'top-0');
				this.DOM.dotsPetals.classList.remove('opacity-100', 'top-0');
				this.DOM.dotsBox.innerHTML = '';
				this.DOM.dotsPetals.innerHTML = '';
			}, 1000);
		}
	}

	populateConfiguratorData(e) {
		this.configuratorData = e.detail?.object?.configurator;

		this.currentProductName = e.detail?.object?.name.toLowerCase();

		if (this.configuratorData) {
			// Colori petali
			const configuratorPetalsOptions = this.configuratorData.petals;

			if (configuratorPetalsOptions) {
				this.petalscurrentName = this.configuratorData.active.petals;

				for (const [name, data] of Object.entries(configuratorPetalsOptions)) {
					this.DOM.dotsPetals.innerHTML +=
						'<span data-configurator-type="petals" data-color="' +
						name +
						'" data-combo="' +
						data.combo +
						'" class="js-change-color dot' +
						(this.petalscurrentName === name ? ' is-active' : '') +
						'" style="background: ' +
						data.color +
						'" data-petals-color-default="' +
						this.petalscurrentName +
						'"></span>';
				}
			}

			// Colori Box
			const configuratorBoxOptions = this.configuratorData.box;

			if (configuratorBoxOptions) {
				this.boxCurrentName = this.configuratorData.active.box;

				this.DOM.boxContainer.classList.remove('hidden');

				for (const [name, data] of Object.entries(configuratorBoxOptions)) {
					this.DOM.dotsBox.innerHTML +=
						'<span data-configurator-type="box" data-color="' +
						name +
						'" data-combo="' +
						data.combo +
						'" class="js-change-color dot' +
						(this.boxCurrentName === name ? ' is-active' : '') +
						'" style="background: ' +
						data.color +
						'"></span>';
				}
			} else {
				this.DOM.boxContainer.classList.add('hidden');
			}

			// Gestione selezione dots
			this.DOM.petalsDots = document.querySelectorAll('.js-dots-petals .js-change-color');
			this.DOM.boxDots = document.querySelectorAll('.js-dots-box .js-change-color');

			this.DOM.petalsDots.forEach((dot) => {
				dot.addEventListener('click', (e) => {
					this.updateDots(dot, this.DOM.boxDots, 'petals');
					this.setConfigurator(e);
				});
				if (dot.classList.contains('is-active')) {
					this.updateDots(dot, this.DOM.boxDots, 'petals');
				}
			});

			this.DOM.boxDots.forEach((dot) => {
				dot.addEventListener('click', (e) => {
					this.updateDots(dot, this.DOM.petalsDots, 'box');
					this.setConfigurator(e);
				});
				if (dot.classList.contains('is-active')) {
					this.updateDots(dot, this.DOM.petalsDots, 'box');
				}
			});
		}
	}

	updateDots(selectedDot, otherDots, type) {
		var combos = selectedDot.getAttribute('data-combo').split(',');

		if (type === 'box') {
			this.boxCurrentName = selectedDot.getAttribute('data-color');
			this.DOM.boxDots.forEach((dot) => {
				dot.classList.remove('is-active');
			});
		} else {
			this.petalscurrentName = selectedDot.getAttribute('data-color');
			this.DOM.petalsDots.forEach((dot) => {
				dot.classList.remove('is-active');
			});
		}

		selectedDot.classList.add('is-active');

		otherDots.forEach((dot) => {
			if (combos.includes(dot.getAttribute('data-color'))) {
				dot.classList.remove('is-disabled');
			} else {
				dot.classList.add('is-disabled');
			}
		});

		this.updateImage();
		this.updateLink();
		this.updateARButtons(); // Aggiorna i bottoni AR
	}

	updateImage() {
		if (this.currentProductName === 'bijou') {
			this.DOM.panelImage.src =
				'https://www.amoroses.it/wp-content/uploads/amoroses-' +
				this.currentProductName +
				'_barattolo-vetro' +
				'_rosa-' +
				this.petalscurrentName +
				'_1-1-480x480.jpg';
		} else {
			this.DOM.panelImage.src =
				'https://www.amoroses.it/wp-content/uploads/amoroses-' +
				this.currentProductName +
				'_box-' +
				this.boxCurrentName +
				'_rosa-' +
				this.petalscurrentName +
				'_1-1-480x480.jpg';
		}
	}

	updateLink() {
		if (this.currentProductName === 'bijou') {
			this.DOM.buyButtonLink.href = 'https://www.amoroses.it/' + this.currentProductName + '?attribute_pa_colore=' + this.petalscurrentName + '&utm_source=experience&utm_medium=exploration';
		} else {
			this.DOM.buyButtonLink.href = 'https://www.amoroses.it/' + this.currentProductName + '?attribute_pa_box=' + this.boxCurrentName + '&attribute_pa_colore=' + this.petalscurrentName + '&utm_source=experience&utm_medium=exploration';
		}
	}

	updateARButtons() {

		if( !this.checkArSupport()) {
			return;
		}

		//const modelPath = `./assets/models/${this.currentProductName}/${this.currentProductName}.glb`;
		const usdzPath = `./assets/models/${this.currentProductName}/${this.currentProductName}.usdz`;

		// if (this.DOM.arButton) {
		// this.DOM.arButton.setAttribute('data-model', modelPath);
		// }

		if (this.DOM.quicklookButton) {
			this.DOM.quicklookButton.href = usdzPath;
		} else {
			this.DOM.quicklookButton.removeAttribute('href');
		}
	}

	setConfigurator(e) {
		this.dotColorSelected = e.target.dataset.color;

		if (e.target.dataset.configuratorType === 'box' && this.dotColorSelected) {
			this.configuratorData.active.box = this.dotColorSelected;
		}

		if (e.target.dataset.configuratorType === 'petals' && this.dotColorSelected) {
			this.configuratorData.active.petals = this.dotColorSelected;
		}

		trigger('colorChange', {
			object: this.currentObject,
			type: e.target.dataset.configuratorType
		});
	}

	checkArSupport() {
		console.log(document.createElement('a').relList.supports('ar'), 'ar');
		return document.createElement('a').relList.supports('ar');
	}

	togglePanel(e) {
		this.DOM.body.classList.toggle('is-active-panel');
	}
}
