import * as THREE from 'three';
import { LOW_PERFORMANCE } from '../utilities/environment';

// Main webGL renderer class
export default class Renderer {
	constructor(scene, container) {
		// Properties
		this.scene = scene;
		this.container = container;
		this.lowPerformance = LOW_PERFORMANCE;

		// Create WebGL renderer and set its antialias
		this.threeRenderer = new THREE.WebGLRenderer({
			antialias: true,
		});

		// Set clear color to fog to enable fog or to hex color for no fog
		this.threeRenderer.setClearColor(scene.fog.color);
		this.threeRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.5));

		this.threeRenderer.physicallyCorrectLights = true;

		this.threeRenderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.threeRenderer.toneMappingExposure = 2.0;
		this.threeRenderer.outputEncoding = THREE.sRGBEncoding;
		this.threeRenderer.outputColorSpace = THREE.sRGBColorSpace;


		// Appends canvas
		container.appendChild(this.threeRenderer.domElement);

		// Shadow map options
		this.threeRenderer.shadowMap.enabled = false;
		//this.threeRenderer.shadowMap.type = THREE.PCFSoftShadowMap; // Può essere cambiato a PCFShadowMap per performance migliori

		// Get anisotropy for textures
		this.threeRenderer.capabilities.getMaxAnisotropy();

		this.threeRenderer.frustumCulled = true;

		// Initial size update set to canvas container
		this.updateSize();

		// Listeners
		document.addEventListener(
			'DOMContentLoaded',
			() => this.updateSize(),
			false,
		);
		window.addEventListener('resize', () => this.updateSize(), false);
	}

	updateSize() {
		this.threeRenderer.setSize(
			// this.container.offsetWidth,
			window.innerWidth,
			// this.container.offsetHeight,
			window.innerHeight,
		);

	}

	render(scene, camera) {
		// Renders scene to canvas target
		this.threeRenderer.render(scene, camera);
	}
}
