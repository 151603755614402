import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/addons/controls/TransformControls.js';

// Controls based on orbit controls
export default class Controls {
	constructor(container, scene) {
		this.scene = scene;
		this.container = container;

		this.initOrbit();
		this.initTransformControls();
	}

	initOrbit() {
		if (this.scene.debug) {
			this.orbitCamera = new THREE.PerspectiveCamera(
				50,
				window.innerWidth / window.innerHeight,
				1,
				2000,
			);
			this.orbitCamera.position.set(0, 2, 8);
			this.orbitCamera.lookAt(0, -10, 0);
			this.orbitControls = new OrbitControls(this.orbitCamera, this.container);

			this.orbitControls.enabled = true;
			this.orbitControls.target.set(0, 0, 0);
			this.orbitControls.autoRotate = false;
			this.orbitControls.autoRotateSpeed = 2;
			this.orbitControls.rotateSpeed = 1;
			this.orbitControls.zoomSpeed = 1;
			this.orbitControls.enableDamping = true;
			this.orbitControls.enableZoom = true;
			this.orbitControls.dampingFactor = 0.05;
			this.orbitControls.enablePan = true;
		}
	}

	initTransformControls() {
		if (this.scene.debug) {
			this.transformControls = new TransformControls(
				this.orbitCamera,
				this.container,
			);
			this.transformControls.addEventListener('dragging-changed', (event) => {
				this.orbitControls.enabled = !event.value;
			});
			this.scene.add(this.transformControls);
		}
	}

	addTransformControlsObject(mesh) {
		if (this.scene.debug) {
			this.transformControls.attach(mesh);
		}
	}

	getTransformControlsObject() {
		if (this.scene.debug) {
			return this.transformControls.object;
		}
	}

	update() {
		if (this.scene.debug) {
			this.orbitControls.update();
		}
	}

	destroy() {
		if (this.scene.debug) {
			this.orbitControls.destroy();
		}
	}

	getCamera() {
		return this.orbitCamera;
	}
}
