import * as THREE from 'three';
import { Sky as SkyShader } from 'three/addons/objects/Sky.js';
import GUI from 'lil-gui';
import { normalize } from '../utilities/math';
export default class Sky {
	constructor(scene, renderer) {
		this.scene = scene;
		this.debug = this.scene.debug || false;
		this.renderer = renderer;
		this.sun = new THREE.Vector3();

		this.effectController = {
			turbidity: 10,
			rayleigh: 3,
			mieCoefficient: 0.005,
			mieDirectionalG: 0.7,
			elevation: 2,
			azimuth: 180,
			exposure: this.renderer.toneMappingExposure
		};

		this.init();
		this.place();
	}

	init() {
		// Add Sky
		this.sky = new SkyShader();
		this.sky.scale.setScalar(450000);
		this.setParams();
	}

	setParams() {
		this.uniforms = this.sky.material.uniforms;
		this.uniforms['turbidity'].value = this.effectController.turbidity;
		this.uniforms['rayleigh'].value = this.effectController.rayleigh;
		this.uniforms['mieCoefficient'].value = this.effectController.mieCoefficient;
		this.uniforms['mieDirectionalG'].value = this.effectController.mieDirectionalG;

		const phi = THREE.MathUtils.degToRad(90 - this.effectController.elevation);
		const theta = THREE.MathUtils.degToRad(this.effectController.azimuth);

		this.sun.setFromSphericalCoords(1, phi, theta);

		this.uniforms['sunPosition'].value.copy(this.sun);

		this.renderer.toneMappingExposure = this.effectController.exposure;
	}

	place() {
		this.scene.add(this.sky);
	}

	update(wheelVal) {
		const initialElevationNormalizedWheelVal = normalize(wheelVal, 0.1, 0.3, 2, 0);
		const endElevationNormalizedWheelVal = normalize(wheelVal, 0.3, 0.8, 0, 2);
		const initialTurbidityNormalizedWheelVal = normalize(wheelVal, 0.1, 0.3, 10, 40);
		const endTurbidityNormalizedWheelVal = normalize(wheelVal, 0.3, 0.8, 40, 10);

		this.effectController.elevation =
			wheelVal > 0.3 ? endElevationNormalizedWheelVal : initialElevationNormalizedWheelVal;
		this.effectController.turbidity =
			wheelVal > 0.3 ? endTurbidityNormalizedWheelVal : initialTurbidityNormalizedWheelVal;
		this.setParams();
	}
}
