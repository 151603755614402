import { CSS2DObject, CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js';

export default class Tooltips {
	constructor(scene, camera) {
		this.scene = scene;
		this.camera = camera;
		this.debug = this.scene.debug || false;

		this.DOM = {
			body: document.body
		};

		this.objects = [];
		this.allObjectsActive = false;
		this.tooltipVisibleOnClick = false;
	}

	init() {
		this.tooltipRenderer = new CSS2DRenderer();
		this.tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
		this.tooltipRenderer.domElement.style.position = 'absolute';
		this.tooltipRenderer.domElement.style.pointerEvents = 'none';
		this.tooltipRenderer.domElement.style.top = '0px';
		this.DOM.body.appendChild(this.tooltipRenderer.domElement);

		if (this.objects) {
			this.objects.forEach(function (element) {
				element.tooltip.forEach(function (item) {
					item.object = new CSS2DObject(document.querySelector(item.selector));
					item.object.position.set(item.offset.x, item.offset.y, item.offset.z);
					item.object.renderOrder = item.renderOrder;

					element.add(item.object);
				});
			});
		}

		this.allObjectsActive = true;

		this.initEvents();
	}

	initEvents() {
		if (this.tooltipVisibleOnClick) {
			window.addEventListener('clickObject', (e) => {
				this.tooltip(e);
			});
		}

		window.addEventListener('resize', () => this.onResize(), false);
	}

	calculateDistance() {
		const cameraPosition = this.camera.cameraControls.getPosition();
		this.objects.forEach((element) => {
			const pointDistance = element.position.distanceTo(cameraPosition);
			element.tooltip.forEach((item) => {
				item.object.element.classList.toggle('tooltip--active', pointDistance < element.distanceShowingTooltip);
			});
		});
	}

	addObjects(objects) {
		this.objects.push(...objects);
		this.init();
	}

	onResize() {
		this.tooltipRenderer.setSize(window.innerWidth, window.innerHeight);
	}

	update() {
		if (this.objects.length > 0 && this.allObjectsActive) {
			this.tooltipRenderer.render(this.scene, this.camera.cameraControls._camera);
			if (!this.tooltipVisibleOnClick) {
				this.calculateDistance();
			}
		}
	}
}
