/**
 * AllProducts module
 */
const Deepmerge = require('deepmerge');
import { trigger } from '../utilities/trigger';

export default class AllProducts {
	/**
	 * @param {string} element - Selector
	 * @param {Object} options - User options
	 */
	constructor(element, options) {
		this.element = element || '.js-all-products';
		this.defaults = {
			triggers: this.element
		};
		this.DOM = {
			html: document.documentElement,
			body: document.body,
			allProducts: document.querySelector(this.element),
			open: true,
			scrollContainer: document.querySelector('.js-scroll-container'),
			button: document.querySelector('.js-button-all-products'),
		};
		this.currentObject;
		this.options = options ? Deepmerge(this.defaults, options) : this.defaults;
		this.initEvents();
	}

	initEvents() {
		document.addEventListener('click', (event) => {
			if (event.target.matches('.js-button-all-products')) {
				this.initAllProducts();
				this.DOM.open = !this.DOM.open
			}
		}, false);
	}


	initAllProducts() {
		this.DOM.body.classList.toggle('is-open-all-products');
		const cards = this.DOM.allProducts.querySelectorAll('.js-card');
		const length = cards.length;
		const delay = length * 250; // Ritardo graduale per ogni carta

		cards.forEach((card, index) => {
			setTimeout(() => {
				card.classList.toggle('ml-[100vw]'); // Animazione iniziale per far partire le carte da fuori
			}, index * 250); // Aggiungi un ritardo graduale a ciascuna carta
		});

		// Logica per gestire l'apertura/chiusura del contenuto di All Products
		if (this.DOM.open) {
			this.DOM.allProducts.classList.remove('opacity-0');
			this.DOM.allProducts.classList.remove('invisible');
			this.DOM.button.classList.add('!bg-white');
			this.DOM.button.classList.add('!px-3', 'lg:!px-6');
			this.DOM.button.innerHTML = "Close";

			trigger('clickAllProducts', {
				action: 'open',
			});
		} else {
			this.DOM.allProducts.classList.add('opacity-0');
			this.DOM.allProducts.classList.add('invisible');
			this.DOM.button.classList.remove('!bg-white');
			this.DOM.button.classList.remove('!px-3', 'lg:!px-6');
			this.DOM.button.innerHTML = "All products";
			trigger('clickAllProducts', {
				action: 'close',
			});

		}
	}



}
