import * as THREE from 'three';
export const cameraIntroPosition = [
	new THREE.Vector3(1.610574903348488, 3.0318973036775476, 3.4864057728943876),
];

export const cameraIntroPositionLookAt = [
	new THREE.Vector3(
		0.08351702243300396,
		0.5123139048131156,
		1.7478965228479235,
	),
];
