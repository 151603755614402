/* eslint-disable no-case-declarations */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { getTemplateUrl } from '../utilities/paths';

export default class Loader {
	constructor() {
		this.templateUrl = getTemplateUrl();
	}

	async load(path, manager, textures) {
		// Todo: verificare cosa serve obbligatorio
		this.type = 'gltf';
		this.modelPath = path;
		this.manager = manager;
		this.textures = textures;
		this.extension = this.modelPath.split('.').pop();

		switch (this.extension) {
			case 'glb':
			case 'gltf':
				// TODO: verificare il manager se serve solo una volta.
				// TODO: verificare nel caso non serva draco come gestirlo.

				// Instantiate a loader
				const loader = new GLTFLoader(this.manager);

				// Optional: Provide a DRACOLoader instance to decode compressed mesh data
				const dracoLoader = new DRACOLoader();
				dracoLoader.setDecoderPath(
					this.templateUrl + 'assets/js/vendors/draco/',
				);
				dracoLoader.setDecoderConfig({ type: 'js' });
				loader.setDRACOLoader(dracoLoader);

				// Load a glTF resource
				return await loader.loadAsync(
					// resource URL
					//templateUrl + '/assets/models/monkey/monkey.gltf',
					this.templateUrl + this.modelPath,

					// // onLoad: called when the resource is loaded
					// (gltf) => {
					// 	// resolve(gltf.scene);
					// 	//this.scene.add(gltf.scene);
					// 	this.loadedModel = gltf.scene;
					// 	console.log('loaded');
					// 	return this.loadedModel;
					// },

					// // onProgress: called while loading is progressing
					// function (xhr) {
					// 	// TODO: Usare arrow function
					// 	console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
					// },
					// // onError: called when loading has errors
					// function (error) {
					// 	console.log('An error happened', error);
					// 	// reject(error);
					// }
				);
			default:
				console.log('File con estensione non supportata');

				break;
		}
	}

	update() {}

	destroy() {}
}
