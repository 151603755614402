import * as THREE from 'three';

import * as dat from 'lil-gui';

// Sets up and places all lights in scene
export default class Light {
	constructor(scene) {
		this.scene = scene;
		this.debug = this.scene.debug || false;

		//this.gui = new dat.GUI();

		this.init();
		this.place();
	}

	init() {
		// Ambient
		this.ambientLight = new THREE.AmbientLight(0xddfff9, 1.2);
		this.ambientLight.visible = true;

		// this.gui.add(this.ambientLight, 'intensity').min(0).max(5).step(0.1);

		// Point light
		// this.pointLight = new THREE.PointLight(0x0000ff, 1, 1);
		// this.pointLight.position.set(0, 2, 2);
		// this.pointLight.visible = true;

		// this.gui.add(this.pointLight.position, 'x').min(-500).max(500).step(1);
		// this.gui.add(this.pointLight.position, 'y').min(-500).max(500).step(1);
		// this.gui.add(this.pointLight.position, 'z').min(-500).max(500).step(1);

		// Directional light
		this.directionalLight = new THREE.DirectionalLight(0xddfff9, 0.9);
		this.directionalLight.position.set(0, 5, 25);
		this.directionalLight.visible = true;

		// Shadow map
		//this.directionalLight.castShadow = true;
		// this.directionalLight.shadow.bias = 0;
		// this.directionalLight.shadow.camera.near = 250;
		// this.directionalLight.shadow.camera.far = 400;
		// this.directionalLight.shadow.camera.left = -100;
		// this.directionalLight.shadow.camera.right = 100;
		// this.directionalLight.shadow.camera.top = 100;
		// this.directionalLight.shadow.camera.bottom = -100;
		// this.directionalLight.shadow.mapSize.width = 2048;
		// this.directionalLight.shadow.mapSize.height = 2048;

		/*this.gui.add(this.directionalLight, 'intensity').min(0).max(1).step(0.001);
		this.gui
			.add(this.directionalLight.position, 'x')
			.min(-500)
			.max(500)
			.step(1);
		this.gui
			.add(this.directionalLight.position, 'y')
			.min(-500)
			.max(500)
			.step(1);
		this.gui
			.add(this.directionalLight.position, 'z')
			.min(-500)
			.max(500)
			.step(1);

		this.gui
			.add(this.directionalLight.position, 'x')
			.min(-Math.PI)
			.max(Math.PI)
			.step(0.001)
			.name('rotation X');
		this.gui
			.add(this.directionalLight.position, 'y')
			.min(-Math.PI)
			.max(Math.PI)
			.step(0.001)
			.name('rotation Y');
		this.gui
			.add(this.directionalLight.position, 'z')
			.min(-Math.PI)
			.max(Math.PI)
			.step(0.001)
			.name('rotation Z');*/

		// Shadow camera helper
		if (this.debug) {
			// this.directionalLightHelper = new THREE.CameraHelper(
			// 	this.directionalLight.shadow.camera,
			// );
			// this.directionalLightHelper.visible = true;
		}
		// Hemisphere light
		// this.hemiLight = new THREE.HemisphereLight(0xc8c8c8, 0x0000ff, 0.55);
		// this.hemiLight.position.set(0, 0, 0);
		// this.hemiLight.visible = true;
		// this.hemiLight.castShadow = true;
	}

	place() {
		this.scene.add(this.ambientLight);

		this.scene.add(this.directionalLight);

		if (this.debug) {
			this.scene.add(this.directionalLightHelper);
		}

		//this.scene.add(this.pointLight)à;

		//this.scene.add(this.hemiLight);
	}
}
