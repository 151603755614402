import Intro from './components/intro';
import WebGl from './webGl/main';

(() => {
	window.addEventListener('load', function () {
		document.documentElement.classList.replace('is-loading', 'is-loaded');
		new WebGl('.js-webgl');
		new Intro('.js-intro');
	});

	window.addEventListener('LOADER_MODELS_COMPLETE', function () {
		const video = document.querySelector('.js-video-intro');
		if (video) {
			video.play();
			video.addEventListener('ended', () => {
				video.remove();
				const enterButton = document.querySelector('.js-enter-button');
				const audioText = document.querySelector('.js-audio-text');
				enterButton.classList.remove('opacity-0', 'invisible');
				audioText.classList.remove('opacity-0', 'invisible');
				enterButton.disabled = false;
			});
		}
	});
})();


