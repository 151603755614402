import * as THREE from 'three';

// Definisci i tuoi array
const cameraPathVectorLarge = [new THREE.Vector3(0.8258806223572255, 0.7218973036775476, 2.6120769957662855),
new THREE.Vector3(0.07327232630685676, 0.559578202402838, 1.764528140219392),
new THREE.Vector3(-1.440865971572867, 0.2508465862206757, 0.9964848537411968),
new THREE.Vector3(-1.0287148396802372, 0.4195357909892774, 0.2563127177172595),
new THREE.Vector3(0.12878923927071728, 0.593954294614464, 0.022341459461711927),
new THREE.Vector3(-0.06669577621593376, 0.6092562096710759, -0.6106929806103802),
new THREE.Vector3(-0.052534869603772044, 0.40515649306989276, -1.3429035456564102),
new THREE.Vector3(-0.09868412526642922, 0.5617648398559161, -2.1149501597853053),
new THREE.Vector3(0.4334370775868588, 0.6000736379173237, -2.2773366904494736),
new THREE.Vector3(0.9335048180892892, 0.6622415430271937, -2.030298173259672),
new THREE.Vector3(0.8575281244759799, 0.5440380061155426, -0.9964080227766592),
new THREE.Vector3(1.4125501428494918, 0.4951691323835258, -0.05431530213589497),
new THREE.Vector3(2.215889006600376, 0.6102780185567527, 0.28103476484089496),
new THREE.Vector3(2.646769763275437, 0.7047768434919466, 1.407584674110836),
new THREE.Vector3(2.4867390176947026, 0.6125638968741405, 2.1671813706676937),
new THREE.Vector3(2.264672273075385, 0.6982022525953576, 2.9840347701758416)];
const cameraPathVectorSmall = [new THREE.Vector3(0.628780261449077, 0.4690894271684177, 2.3077105451597477),
new THREE.Vector3(-0.10859560820045527, 0.34921676107764654, 1.5754728995908036),
new THREE.Vector3(-1.1986201176132503, 0.25523327074295143, 1.0054007850416544),
new THREE.Vector3(-1.2525372726258328, 0.4195357909892774, 0.4376533867913601),
new THREE.Vector3(-0.0857740343626695, 0.41795997890958847, 0.08222168067041735),
new THREE.Vector3(-0.06669577621593376, 0.6092562096710759, -0.6106929806103802),
new THREE.Vector3(-0.1213017325883072, 0.40515649306989276, -1.3429035456564102),
new THREE.Vector3(0.24274152615979483, 0.5617648398559161, -2.1882352075928493),
new THREE.Vector3(0.8519337286124838, 0.539081768345931, -2.210942955743892),
new THREE.Vector3(0.9272493545650604, 0.5126326719260037, -1.5044397274928494),
new THREE.Vector3(1.6136263703228053, 0.46061450071719934, -0.35289804280600584),
new THREE.Vector3(2.250458653840064, 0.5349698541714185, 0.22204772219600022),
new THREE.Vector3(2.837093548746858, 0.7047768434919466, 1.1322324763236078),
new THREE.Vector3(2.4867390176947026, 0.6125638968741405, 2.1671813706676937),
new THREE.Vector3(2.264672273075385, 0.6982022525953576, 2.9840347701758416)];
const cameraPathVectorLookAtLarge = [new THREE.Vector3(-0.0420228634109876, 0.5123139048131156, 1.6304347944703093),
new THREE.Vector3(-1.2304779279688107, 0.2452718019705648, 1.0010101759779735),
new THREE.Vector3(-0.8735991276180319, 0.4580583522956232, 0.6358093970640687),
new THREE.Vector3(-0.06523559694765385, 0.4580583522956232, 0.483298600657584),
new THREE.Vector3(0.05012205886316359, 0.3794448272496109, -0.198996427244734),
new THREE.Vector3(-0.11531675642133882, 0.47786037703131035, -0.8173854756548803),
new THREE.Vector3(-0.23602001627252295, 0.47786037703131035, -1.512915043063637),
new THREE.Vector3(0.03632746147407284, 0.4580583522956232, -2.1398444698140304),
new THREE.Vector3(0.866715775105336, 0.6492409185840754, -2.5461523036015077),
new THREE.Vector3(0.8670663727385708, 0.5373775777621853, -0.4860246745563903),
new THREE.Vector3(2.5522508101191868, 0.5567615768420899, 0.6812069706655979),
new THREE.Vector3(2.2676911168142486, 0.5567615768420899, 1.6568149113588853),
new THREE.Vector3(1.6283081177124155, 0.6953701441965339, 3.030999759191878)];
const cameraPathVectorLookAtSmall = [new THREE.Vector3(-0.17122880879932834, 0.3487796631152086, 1.4374166761975544),
new THREE.Vector3(-0.25479943710319064, 0.3487796631152086, 1.3476924242637578),
new THREE.Vector3(-0.8127468356007157, 0.2915709913533435, 1.0334161646927387),
new THREE.Vector3(-0.8440357247340768, 0.4580583522956232, 0.23436001265988937),
new THREE.Vector3(0.28282399733737695, 0.4580583522956232, 0.07500443994587491),
new THREE.Vector3(0.28282399733737695, 0.4580583522956232, 0.07500443994587491),
new THREE.Vector3(0.12770216406496596, 0.3794448272496109, -0.48418505820330326),
new THREE.Vector3(0.10151007033607146, 0.47786037703131035, -0.8173854756548803),
new THREE.Vector3(-0.23602001627252295, 0.47786037703131035, -1.7310928742256737),
new THREE.Vector3(0.4431627775599323, 0.5915905157669425, -2.604518817974896),
new THREE.Vector3(0.805510385424474, 0.5915905157669425, -2.312290819248277),
new THREE.Vector3(1.0411493834826457, 0.5323499117756343, -1.931006515794761),
new THREE.Vector3(1.6213776467177088, 0.8624021942227257, -0.25483716674579027),
new THREE.Vector3(2.710596088934992, 0.6696727280523607, 0.5388072530383151),
new THREE.Vector3(2.5326081712949957, 0.5860284574402743, 1.6568149113588853),
new THREE.Vector3(1.6283081177124155, 0.6953701441965339, 3.030999759191878)];

// Funzione per verificare la larghezza del viewport
function isLargeViewport() {
    // Sostituisci 'lg' con la larghezza effettiva che desideri controllare
    return window.matchMedia('(min-width: 1024px)').matches;
}

// Esporta in base alla larghezza del viewport
export const cameraPathVector = isLargeViewport() ? cameraPathVectorLarge : cameraPathVectorSmall;
export const cameraPathVectorLookAt = isLargeViewport() ? cameraPathVectorLookAtLarge : cameraPathVectorLookAtSmall;
