import * as THREE from 'three';
import GUI from 'lil-gui';
//import { GUI } from 'dat.gui';

import { cameraIntroPosition, cameraIntroPositionLookAt } from './cameraIntro';
import { cameraPathVector, cameraPathVectorLookAt } from './cameraPath';
import Camera from './camera';
import Renderer from './renderer';
import Light from './light';
import Sky from './sky';
// import Water from './water';
import Controls from './controls';
import Stats from './stats';
import Loader from './loader';
// import PostProcessing from './postProcessing';
import Panel from '../components/panel';
import AllProducts from '../components/allProducts';
import { trigger } from '../utilities/trigger';
import { normalize } from '../utilities/math';
import { Howl, Howler } from 'howler';
import cylinderVertexShader from '../../shaders/cylinder/vertex.glsl';
import cylinderFragmentShader from '../../shaders/cylinder/fragment.glsl';
// import rayVertexShader from '../../shaders/ray/vertex.glsl';
// import rayFragmentShader from '../../shaders/ray/fragment.glsl';
import plantsVertexShader from '../../shaders/plants/vertex.glsl';
import plantsFragmentShader from '../../shaders/plants/fragment.glsl';
import sphereVertexShader from '../../shaders/sphere/vertex.glsl';
import sphereFragmentShader from '../../shaders/sphere/fragment.glsl';
import { AnimationMixer } from 'three';
import Tooltips from './tooltips';
import Particles from './particles';
import { LOW_PERFORMANCE, DEBUG_MODE } from '../utilities/environment';


// import FakeGlowMaterial from './fakeGlowMaterial.js'
export default class WebGl {
	/**
	 * @param {string} containerSelector - Selector
	 * @param {Object} options - User options
	 */
	constructor(containerSelector) {
		this.DOM = {
			html: document.documentElement,
			body: document.body,
			container: document.querySelector(containerSelector),
			loader: document.querySelector('#js-loader'),
			loaderIntro: document.querySelector('.js-loader-intro'),
			moreInfo: document.querySelector('.js-more-info-mobile'),
			moreInfoClose: document.querySelector('.js-more-info-mobile-close'),
			loaderText: document.querySelector('.js-loader-text'),
			loaderTextEnter: document.querySelector('.js-loader-text-enter'),
			loaderButton: document.querySelector('.js-loader-button'),
			enterButton: document.querySelector('.js-enter-button'),
			scrollContainer: document.querySelector('.js-scroll-container'),
			scrollBar: document.querySelectorAll('.js-scrollbar'),
			scrollInfo: document.querySelector('.js-scroll-info'),
			scrollInfoClose: document.querySelector('.js-scroll-info-close'),
			scrollButton: document.querySelectorAll('.js-scroll-to'),
			scrollImage: document.querySelectorAll('.js-scroll-image')
		};

		if (!this.DOM.container) {
			console.warn('Missing app container');
			return;
		}

		this.debug = DEBUG_MODE;
		this.lowPerformance = LOW_PERFORMANCE;

		// Start Three clock
		this.clock = new THREE.Clock();

		// Main scene creation
		this.scene = new THREE.Scene();

		// Scene properties
		this.scene.fog = new THREE.FogExp2(0xf0cb94, 0.3);
		this.scene.debug = this.debug;

		// Main renderer constructor
		this.renderer = new Renderer(this.scene, this.DOM.container);

		//Sizes
		this.width = this.renderer.threeRenderer.domElement.width;
		this.height = this.renderer.threeRenderer.domElement.height;

		// Camera
		this.cameraPathVector = cameraPathVector;
		this.cameraPathVectorLookAt = cameraPathVectorLookAt;
		this.cameraIntroPosition = cameraIntroPosition;
		this.cameraIntroPositionLookAt = cameraIntroPositionLookAt;

		this.camera = new Camera(
			this.renderer.threeRenderer,
			this.scene,
			this.cameraIntroPosition[0],
			this.cameraIntroPositionLookAt[0]
		);

		this.cameraToObjectAnimated = false;
		this.cameraOnPath = true;
		this.cameraPreviousPosition = {};
		this.cameraPreviousLookAt = {};
		this.clickedObject = null;
		this.showAllProducts = false;
		this.tutorialOpen = true;

		// Controls
		this.controls = new Controls(this.DOM.container, this.scene);

		// Create and place lights in scene
		this.light = new Light(this.scene);

		// Sky
		this.sky = new Sky(this.scene, this.renderer);

		// Water
		//this.water = new Water(this.scene, this.renderer);

		// Raycaster
		this.raycasterObjects = [];
		this.raycasterPetals = [];
		this.raycaster = new THREE.Raycaster();
		this.mousePointer = new THREE.Vector2();
		this.raycasterIntersected;
		this.intersectObject = [];
		this.intersectPetals = [];
		this.previousIntersectObject = [];
		this.intersectObjectHover = false;
		this.intersectPetalsHover = false;


		// Mixers
		this.petalsMixers = new Map()



		// Postprocessing;
		// this.postProcessing = new PostProcessing(
		// 	this.DOM.container,
		// 	this.scene,
		// 	this.renderer.threeRenderer,
		// 	this.camera.threeCamera,
		// );

		// Scroll / Touch
		this.wheelVal = 0;
		this.wheelTimer = null;
		this.wheelActive = false;
		this.touchStartY = 0;
		this.touchDeltaY = 0;
		this.scrollToAnimationTime = 5; // Durata dell'animazione in secondi

		// Path
		this.pathPoint = new THREE.Vector3();
		this.pathLoop = true;
		this.splineHelperObjects = {
			cameraPath: [],
			cameraPathLookAt: []
		};

		// Tooltips
		this.tooltips = new Tooltips(this.scene, this.camera);

		// Sound
		this.voice = new Howl({
			src: ['assets/audio/voice.mp3'],
		});

		this.sound1 = new Howl({
			src: ['assets/audio/1.mp3']
		});
		this.sound2 = new Howl({
			src: ['assets/audio/2.mp3'],
			loop: true,
			volume: 0
		});
		this.sound3 = new Howl({
			src: ['assets/audio/3.mp3']
		});

		// Scrollbar
		this.objectsScrollPosition = [];
		this.DOM.scrollBar.forEach((elem, index) => {
			this.objectsScrollPosition.push(parseFloat(elem.dataset.scrollTo));
		});

		// Oscillazione
		this.velocitàOscillazione = 0.75;
		this.oscillationTime = 0;

		// Animated petals intro
		this.animatedPetalsIntro = false;

		// Particles
		this.gu = {
			time: { value: 0 }
		};

		//Intro animation delay
		this.delayIntro = 1500
		//Arrey clone petal hover/intro
		this.introPetalsClones = []

		this.particles = new Particles(this.gu);
		this.particles.name = 'Particles';
		this.scene.add(this.particles);

		this.cylinderActive = true;

		this.init();
	}

	async init() {
		// Init methods
		await this.loadModels();
		this.setWorld();
		this.setStats(this.debug);
		this.animate();
		this.createCameraPath();
		this.addSoundControl();
		this.initEvents();
		new Panel();
		new AllProducts();

		if (this.lowPerformance) {
			this.DOM.scrollContainer.remove();
		}
	}



	initEvents() {
		window.addEventListener('INTRO_FINISHED', () => {
			this.introAnimation();

			setTimeout(() => {
				// this.voice.stop()
				this.voice.fade(1, 0, 3000);
			}, 1000);


			window.addEventListener('click', (e) => {
				this.onGlobalClick(e);
			});
			window.addEventListener('pointermove', (e) => this.onPointerMove(e));
			window.addEventListener('wheel', (e) => this.onWheel(e));
			if ('ontouchstart' in window) {
				window.addEventListener('touchstart', (e) => {
					this.touchStartY = e.touches[0].clientY;
				});
				window.addEventListener('touchmove', (e) => {
					this.touchDeltaY = this.touchStartY - e.touches[0].clientY;
					this.onWheel(e);
				});
			}
			this.DOM.scrollButton.forEach((elem) => {
				elem.addEventListener('click', (e) => {
					this.clickScrollTo = true;
					this.clickScrollToTarget = e.target.dataset.scrollTo;
					this.clickScrollDirection = this.clickScrollToTarget >= this.wheelVal ? 'positive' : 'negative';
				});
			});

			window.addEventListener('colorChange', (e) => {
				this.setTexture(e);
				this.playRoseTwisted(e.detail.object);
			});

			window.addEventListener('clickAllProducts', (e) => {
				console.log(e.detail.action);
				this.showAllProducts = e.detail.action === 'open';
			});
		});

		// window.addEventListener('resize', () => this.onResize(), false);
	}

	async loadModels() {
		// Models
		this.loader = new Loader();
		this.manager = new THREE.LoadingManager();
		this.DOM.body.classList.add('is-intro-enter');
		// this.manager.onStart = (url, itemsLoaded, itemsTotal) => {
		// 	console.log(
		// 		`Started loading file: ${url}.\nLoaded ${itemsLoaded} of ${itemsTotal} files.`,
		// 	);
		// };

		// this.manager.onStart = (url, itemsLoaded, itemsTotal) => {
		// 	console.log('start', `${url}: ${itemsLoaded} ${itemsTotal}`);
		// };

		this.manager.onLoad = () => {
			trigger('LOADER_MODELS_COMPLETE');
			this.DOM.body.classList.add('is-loader-ended');
			// this.DOM.enterButton.classList.remove('opacity-0');

			this.DOM.enterButton.addEventListener('click', () => {
				this.sound2.play();
				this.sound2.fade(0, 1, 4000);

				setTimeout(() => {
					this.voice.play();
					this.voice.fade(0, 1, 1000);
				}, 1000);
				trigger('ENTER_SCENE');
			});
		};

		this.manager.onProgress = (url, itemsLoaded, itemsTotal) => {

			let percentage = `${Math.round((itemsLoaded / itemsTotal) * 100)}`;
			this.DOM.loaderText.innerHTML = `${percentage}`;
			document.getElementById('progressBar').style.width = `${percentage}%`;

			if (this.scene.debug) {
				console.log('progress', `${url}: ${itemsLoaded} ${itemsTotal}`);
				console.log(`${(itemsLoaded / itemsTotal) * 100}% loaded`);
			}
			if (itemsLoaded == itemsTotal) {
				//alert('Loaded Total');
			}
		};

		const allModels = [
			this.loader.load('assets/models/rose/rose.glb', this.manager),
			this.loader.load('assets/models/environment/env.glb', this.manager),
			this.loader.load('assets/models/superior/superior.glb', this.manager),
			this.loader.load('assets/models/prestige/prestige.glb', this.manager),
			this.loader.load('assets/models/petite/petite.glb', this.manager),
			this.loader.load('assets/models/bijou/bijou.glb', this.manager),
			this.loader.load('assets/models/environment/emitter_twist3.glb', this.manager),
			this.loader.load('assets/models/environment/emitter_intro.glb', this.manager),
			this.loader.load('assets/models/environment/petals-hover.glb', this.manager),
			this.loader.load('assets/models/environment/pivot.glb', this.manager),
			this.loader.load('assets/models/environment/monstrera.glb', this.manager),
			this.loader.load('assets/models/environment/dracaena.glb', this.manager),
			//this.loader.load('assets/models/environment/ray.glb', this.manager)
		];

		try {
			[
				this.modelRose,
				this.modelEnvironment,
				this.modelPrestige,
				this.modelSuperior,
				this.modelPetite,
				this.modelBijou,
				this.modelTwistedPetals,
				this.modelIntroPetals,
				this.modelHoverPetals,
				this.modelHoverPivot,
				this.modelMonstrera,
				this.modelDracaena,
				//this.modelRay
			] = await Promise.all(allModels);
			//[this.modelEnvironment] = await Promise.all(allModels);
		} catch (error) {
			console.log('Loader error: ', error);
		}

		/**
		 * Environment map
		 */
		// this.environmentMap = cubeTextureLoader.load([
		// 	'/assets/textures/environmentMaps/2/px.jpg',
		// 	'/assets/textures/environmentMaps/2/nx.jpg',
		// 	'/assets/textures/environmentMaps/2/py.jpg',
		// 	'/assets/textures/environmentMaps/2/ny.jpg',
		// 	'/assets/textures/environmentMaps/2/pz.jpg',
		// 	'/assets/textures/environmentMaps/2/nz.jpg',
		// ]);
		// this.environmentMap.encoding = sRGBEncoding;
		// this.scene.background = this.environmentMap;
		// this.scene.environment = this.environmentMap;

		// debugObject.envMapIntensity = 8.5;
		// gui
		// 	.add(debugObject, 'envMapIntensity')
		// 	.min(0)
		// 	.max(10)
		// 	.step(0.001)
		// 	.onChange(updateAllMaterials);
	}




	setTexture(e) {
		const object = e.detail.object;
		const activeColors = e.detail.object.configurator.active;

		if (!object) {
			return;
		}

		/* TODO: Verificare se usare un'unico texture loader */
		const textureLoader = new THREE.TextureLoader();


		object.children.forEach((child) => {

			if (e.detail.type === 'box') {
				let texturePath =
					'assets/textures/' + e.detail.object.name.toLowerCase() + '-' + e.detail.type + '-' + activeColors.box + '.jpg';

				if (child.name === object.configurator['box-layers'][0]) {
					textureLoader.load(texturePath, function (texture) {
						texture.flipY = false;
						texture.colorSpace = THREE.SRGBColorSpace;
						texture.encoding = THREE.sRGBEncoding;
						child.material.map = texture;
						child.material.needsUpdate = true;
					});
				}
			}

			if (e.detail.type === 'petals') {
				let texturePath = 'assets/textures/' + e.detail.type + '-' + activeColors.petals + '.jpg';

				object.configurator['petals-layers'].forEach((layer) => {
					if (child.name === layer) {
						textureLoader.load(texturePath, function (texture) {
							if (child.children.length > 0) {
								child.children.forEach((item) => {
									texture.flipY = false;
									texture.colorSpace = THREE.sRGBColorSpace;
									texture.encoding = THREE.sRGBEncoding;
									item.material.map = texture;
									item.material.needsUpdate = true;
								});
							} else {
								texture.flipY = false;
								texture.colorSpace = THREE.sRGBColorSpace;
								texture.encoding = THREE.sRGBEncoding;
								child.material.map = texture;
								child.material.needsUpdate = true;
							}
						});
					}
				});
			}
		});
	}

	playRoseTwisted(object) {
		const activeColors = object.configurator.active;

		let texturePath = '';
		const textureLoader = new THREE.TextureLoader();
		texturePath = 'assets/textures/petals-' + activeColors.petals + '.jpg';

		this.modelTwistedPetals.scene.traverse((node) => {
			if (node.isMesh) {
				textureLoader.load(texturePath, function (texture) {
					texture.flipY = false;
					texture.colorSpace = THREE.SRGBColorSpace;
					node.material.map = texture;
					node.material.needsUpdate = true;
				});
			}
		});

		if (this.modelTwistedPetals.animations) {
			this.modelTwistedPetals.scene.position.x = this.clickedObject.position.x;
			this.modelTwistedPetals.scene.position.y = this.clickedObject.position.y - 0.285;
			this.modelTwistedPetals.scene.position.z = this.clickedObject.position.z;
			this.modelTwistedPetals.animations.forEach((e, index) => {

				this.actionModelPetalsPlay = this.mixerModelPetals.clipAction(
					this.modelTwistedPetals.animations[index]
				);
				this.actionModelPetalsPlay.reset();
				this.actionModelPetalsPlay.clampWhenFinished = true;
				//this.actionModelPetalsPlay.timeScale = 1.5;
				this.actionModelPetalsPlay.setLoop(THREE.LoopOnce, 1);

				this.actionModelPetalsPlay.play();
			});
		}
	}


	cloneAndPositionPetals(modelToClone, pivotChildren) {

		pivotChildren.forEach((pivotChild, index) => {
			let clone = modelToClone.scene.clone(); // Clona il modello
			clone.name = `clonePetals${index + 1}`; // Assegna un nome incrementale al clone

			// Copia la posizione e la rotazione dal pivotChild corrispondente
			clone.position.copy(pivotChild.position);
			clone.rotation.copy(pivotChild.rotation);
			clone.animations = [...modelToClone.animations]; // Assicurati di clonare le animazioni se necessario

			clone.children.forEach((item) => {
				const animationHoverItem = modelToClone.animations.find(t => t.name.includes("Hover") && t.name.replace(/\./g, '').replace(/Action/g, '').replace(/Hover/g, ''));
				if (animationHoverItem) {
					item.children[0].animations.push(animationHoverItem);
					this.raycasterPetals.push(item.children[0]);
				}
			});

			this.introPetalsClones.push(clone); // Aggiungi il clone all'array
		});
	}






	setWorld() {


		//Group Intro
		this.groupIntro = new THREE.Group();

		//Intro Plane
		this.geometryIntroPlane = new THREE.PlaneBufferGeometry(1, 1, 8, 8);
		this.materialIntroPlane = new THREE.MeshBasicMaterial({
			color: 0xa38253,
			side: THREE.DoubleSide,
			transparent: true
		});
		this.plane = new THREE.Mesh(this.geometryIntroPlane, this.materialIntroPlane);
		this.plane.position.set(0, 0, -1.45);
		this.plane.scale.set(10, 3, 3);

		this.groupIntro.add(this.plane);

		//Rose
		this.modelRose = this.modelRose.scene;
		this.groupIntro.add(this.modelRose);
		this.modelRose.position.set(-1.2, 0, -1.2);
		this.modelRose.scale.set(4, 4, 4);

		this.mixerModelRose = new AnimationMixer(this.modelRose);
		if (this.modelRose.animations) {
			this.modelRose.animations.forEach((e, index) => {
				this.actionModelRose = this.mixerModelRose.clipAction(this.modelRose.animations[index]);
				this.actionModelRose.setLoop(THREE.LoopOnce);
				this.actionModelRose.clampWhenFinished = true;
				this.actionModelRose.enable = true;
				this.actionModelRose.play();
			});
		}

		this.groupIntro.add(this.modelRose);

		this.modelRose1 = this.modelRose.clone();
		this.groupIntro.add(this.modelRose1);
		this.modelRose1.position.set(0.12, -0.6, -0.9);
		this.modelRose1.scale.set(5, 5, 5);

		this.mixerModelRose = new AnimationMixer(this.modelRose1);
		if (this.modelRose1.animations) {
			this.modelRose1.animations.forEach((e, index) => {
				this.actionmodelRose1 = this.mixerModelRose.clipAction(this.modelRose1.animations[index]);
				this.actionModelRose1.setLoop(THREE.LoopOnce);
				this.actionModelRose1.clampWhenFinished = true;
				this.actionModelRose1.enable = true;
				this.actionmodelRose1.play();
			});
		}

		this.groupIntro.add(this.modelRose1);

		this.modelRose2 = this.modelRose.clone();
		this.groupIntro.add(this.modelRose2);
		this.modelRose2.position.set(0.95, 0.25, -1);
		this.modelRose2.rotation.set(0.85, 0, 0);
		this.modelRose2.scale.set(2.5, 2.5, 2.5);

		this.mixerModelRose = new AnimationMixer(this.modelRose2);
		if (this.modelRose2.animations) {
			this.modelRose2.animations.forEach((e, index) => {
				this.actionmodelRose2 = this.mixerModelRose.clipAction(this.modelRose2.animations[index]);
				this.actionModelRose2.setLoop(THREE.LoopOnce);
				this.actionModelRose2.clampWhenFinished = true;
				this.actionModelRose2.enable = true;
				this.actionmodelRose2.play();
			});
		}

		this.groupIntro.add(this.modelRose1);

		this.groupIntro.position.set(
			this.camera.cameraControls.getPosition().x,
			this.camera.cameraControls.getPosition().y,
			this.camera.cameraControls.getPosition().z
		);

		//this.camera.cameraControls._camera.rotation.copy(this.groupIntro.rotation);

		this.groupIntro.rotation.set(-0.93, 0.48, 0.555);

		//Group objects
		this.groupObjects = new THREE.Group();

		//Variable for InstanceMesh plant
		const dummyDracaena = new THREE.Object3D();
		//Texture loading
		this.textureLoader = new THREE.TextureLoader();

		//Plants Node-Dracaena
		this.textureDracaena = this.textureLoader.load('assets/textures/Dracaena_baked.jpg');

		this.DracaenaMaterial = new THREE.ShaderMaterial({
			uniforms: {
				uTexture: { value: this.textureDracaena },
				u_windSpeed: { value: 1.0 },
				u_windTime: { value: 0.0 }
			},
			vertexShader: plantsVertexShader,
			fragmentShader: plantsFragmentShader,
			side: THREE.DoubleSide,
			blending: THREE.NormalBlending,
			depthTest: true,
			transparent: true
		});

		this.modelDracaena.scene.traverse((object) => {
			if (object.isMesh) {
				object.material = this.DracaenaMaterial;
			}
		});

		const instanceNumberDracaena = this.modelDracaena.scene.children[0].instanceMatrix.count; // Numero di istanze nel modello "surface"
		const geometryDracaena = this.modelDracaena.scene.children[0].geometry;

		// Sposta il punto più basso della geometria della lama d'erba a 0.
		geometryDracaena.translate(0, 0.5, 0);
		const commonYDracaena = 0.0;
		// Crea una matrice di trasformazione per impostare la coordinata Y comune per tutte le istanze
		const commonMatrixDracaena = new THREE.Matrix4().makeTranslation(0, commonYDracaena, 0);

		const instancedMeshDracaena = new THREE.InstancedMesh(
			geometryDracaena,
			this.DracaenaMaterial,
			instanceNumberDracaena
		);

		for (let i = 0; i < instanceNumberDracaena; i++) {
			const matrix = new THREE.Matrix4();
			matrix.fromArray(this.modelDracaena.scene.children[0].instanceMatrix.array, i * 16);

			// Applica la matrice di trasformazione comune per impostare la coordinata Y
			matrix.premultiply(commonMatrixDracaena);

			const position = new THREE.Vector3();
			const quaternion = new THREE.Quaternion();
			const scale = new THREE.Vector3();

			matrix.decompose(position, quaternion, scale);

			dummyDracaena.position.copy(position);
			dummyDracaena.quaternion.copy(quaternion);
			dummyDracaena.scale.copy(scale);

			dummyDracaena.updateMatrix();
			instancedMeshDracaena.setMatrixAt(i, dummyDracaena.matrix);
		}

		instancedMeshDracaena.position.copy(this.modelDracaena.scene.children[0].position);
		instancedMeshDracaena.rotation.copy(this.modelDracaena.scene.children[0].rotation);
		instancedMeshDracaena.position.y = -0.045;

		this.scene.add(instancedMeshDracaena);

		//Variable for InstanceMesh plant
		const dummyMonstrera = new THREE.Object3D();

		this.textureMonstrera = this.textureLoader.load('assets/textures/Monstrera_baked.jpg');

		this.MonstreraMaterial = new THREE.ShaderMaterial({
			uniforms: {
				uTexture: { value: this.textureMonstrera },
				u_windSpeed: { value: 1.0 },
				u_windTime: { value: 0.0 }
			},
			vertexShader: plantsVertexShader,
			fragmentShader: plantsFragmentShader,
			side: THREE.DoubleSide,
			blending: THREE.NormalBlending,
			depthTest: true,
			transparent: true
		});

		this.modelMonstrera.scene.traverse((object) => {
			if (object.isMesh) {
				object.material = this.MonstreraMaterial;
			}
		});

		const instanceNumberMonstrera = this.modelMonstrera.scene.children[0].instanceMatrix.count; // Numero di istanze nel modello "surface"
		const geometryMonstrera = this.modelMonstrera.scene.children[0].geometry;

		// Sposta il punto più basso della geometria della lama d'erba a 0.
		geometryMonstrera.translate(0, 0.5, 0);
		const commonYMonstrera = 0.0;
		// Crea una matrice di trasformazione per impostare la coordinata Y comune per tutte le istanze
		const commonMatrixMonstrera = new THREE.Matrix4().makeTranslation(0, commonYMonstrera, 0);

		const instancedMeshMonstrera = new THREE.InstancedMesh(
			geometryMonstrera,
			this.MonstreraMaterial,
			instanceNumberMonstrera
		);

		for (let i = 0; i < instanceNumberMonstrera; i++) {
			const matrix = new THREE.Matrix4();
			matrix.fromArray(this.modelMonstrera.scene.children[0].instanceMatrix.array, i * 16);

			// Applica la matrice di trasformazione comune per impostare la coordinata Y
			matrix.premultiply(commonMatrixMonstrera);

			const position = new THREE.Vector3();
			const quaternion = new THREE.Quaternion();
			const scale = new THREE.Vector3();

			matrix.decompose(position, quaternion, scale);

			dummyMonstrera.position.copy(position);
			dummyMonstrera.quaternion.copy(quaternion);
			dummyMonstrera.scale.copy(scale);

			dummyMonstrera.updateMatrix();
			instancedMeshMonstrera.setMatrixAt(i, dummyMonstrera.matrix);
		}

		instancedMeshMonstrera.position.copy(this.modelMonstrera.scene.children[0].position);
		instancedMeshMonstrera.rotation.copy(this.modelMonstrera.scene.children[0].rotation);
		instancedMeshMonstrera.position.y = -0.045;
		this.scene.add(instancedMeshMonstrera);

		// Enviroment
		// this.modelEnvironment.castShadow = true;
		// this.modelEnvironment.receiveShadow = true;
		// this.modelEnvironment.scene.receiveShadow = true;
		// this.modelEnvironment.scene.castShadow = true;
		this.groupObjects.add(this.modelEnvironment.scene);

		// Avoid Fog
		let objectNames = ['Colonne', 'Paletto002', 'Vaso002', 'Libri', 'Libro', 'ArchiColonnina', 'Comodino'];

		objectNames.forEach((name) => {
			const object = this.modelEnvironment.scene.getObjectByName(name);
			if (object && object.material) {
				object.traverse((child) => {
					if (child.isMesh && child.material) {
						child.material.fog = false;
					}
				});
			}
		});

		//Env.Sphere
		const envSphereGeometry_size = 20;
		const envSphereGeometry_frag = 5;
		const uniformsEnvSphere = {
			time: {
				type: 'f',
				value: 0.0
			},
			RGBr: {
				type: 'f',
				value: 0.0
			},
			RGBg: {
				type: 'f',
				value: 0.0
			},
			RGBb: {
				type: 'f',
				value: 0.0
			},
			RGBn: {
				type: 'f',
				value: 0.0
			},
			RGBm: {
				type: 'f',
				value: 0.0
			},
			morph: {
				type: 'f',
				value: 0.0
			},
			dnoise: {
				type: 'f',
				value: 0.0
			},
			psize: {
				type: 'f',
				value: 3.0
			}
		};

		this.envSphereGeometry = new THREE.IcosahedronBufferGeometry(envSphereGeometry_size, envSphereGeometry_frag);
		this.envSphereMaterial = new THREE.ShaderMaterial({
			uniforms: uniformsEnvSphere,
			side: THREE.DoubleSide,
			vertexShader: sphereVertexShader,
			fragmentShader: sphereFragmentShader,
			transparent: true
		});
		this.SpherePoint = new THREE.Points(this.envSphereGeometry, this.envSphereMaterial);
		this.SphereMesh = new THREE.Mesh(this.envSphereGeometry, this.envSphereMaterial);
		this.SphereMesh.geometry.verticesNeedUpdate = true;
		this.SphereMesh.geometry.morphTargetsNeedUpdate = true;
		this.SphereMesh.scale.set(1, 1, 1);
		this.SphereMesh.rotation.set(0, 3.14, 0);
		this.scene.add(this.SphereMesh);

		if (!this.lowPerformance) {
			this.modelHoverPetals.scene.scale.set(1, 1, 1);
			this.modelHoverPetals.scene.position.set(-0.736, 0.075, 0.654);

			this.modelHoverPetals.scene.children.forEach((item) => {

				const animationItem = this.modelHoverPetals.animations.find(t => t.name && t.name.includes("Hover") && t.name.replace(/\./g, '').replace(/Action/g, '').replace(/Hover/g, '') === item.children[0].name);
				if (item.children[0]?.animations) {
					item.children[0].animations.push(animationItem)
					const geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
					const material = new THREE.MeshBasicMaterial({ visible: false });
					const invisibleBox = new THREE.Mesh(geometry, material);

					// Aggiungi la geometria invisibile come figlio dell'item per estendere il suo bounding box
					item.children[0].add(invisibleBox);
					this.raycasterPetals.push(item.children[0]);
				}
			});

		}
		this.cloneAndPositionPetals(this.modelHoverPetals, this.modelHoverPivot.scene.children);


		//Ray
		// this.modelRay = this.modelRay.scene
		// console.log(this.modelRay, 'RAY');
		// const fakeGlowMaterial = new FakeGlowMaterial({
		// 	glowColor: '#fff',
		// 	//falloff: 5,
		// 	//glowInternalRadius: .2,
		// 	//glowSharpness: .5,
		// 	opacity: .0275,
		// 	side: THREE.FrontSide,
		// 	//depthTest: true,
		// })
		// this.modelRay.traverse((child) => {
		// 	if (child.isMesh) {
		// 		child.material = fakeGlowMaterial;
		// 		child.needsUpdate = true;
		// 	}
		// 	this.groupObjects.add(child);
		// });

		//this.groupObjects.add(this.modelRay);

		// Superior
		this.modelSuperior = this.modelSuperior.scene;
		this.modelSuperior.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});

		this.modelSuperior.position.set(-0.936, 0.22, 0.654);
		this.modelSuperior.rotation.set(0, -3, 0);
		this.modelSuperior.scale.set(0.9, 0.9, 0.9);
		this.modelSuperior.name = 'Superior';
		this.modelSuperior.description =
			"The highlight that adds <strong>elegance</strong> to any setting.<br/> The ultimate symbol of romance and <strong>luxury</strong>.<br/>The perfect blend of elegance and the splendor of nature.<br/><br/>A <strong>timeless treasure</strong> that will captivate hearts and inspire dreams for years to come.<br/>This combination of roses and craftsmanship will provide a truly <strong>magical experience</strong>.";
		this.modelSuperior.cameraInteraction = '360';
		this.modelSuperior.cameraRotationDegreesPerSecond = 90;
		this.modelSuperior.cameraDirection = 'right';
		this.modelSuperior.cameraOffset = { x: 0.2, y: 0.145, z: 0.2 };
		this.modelSuperior.distanceShowingTooltip = 2;
		this.modelSuperior.configurator = {
			petals: {
				rosso: {
					color: '#e32429',
					combo: ['legno-black', 'legno-natural']
				}
			},
			'petals-layers': ['Bulbi interni001', 'Bulbi esterni001'],
			box: {
				'legno-black': {
					color: '#000000',
					combo: ['rosso']
				},
				'legno-natural': {
					color: '#FFFFFF',
					combo: ['rosso']
				}
			},
			'box-layers': ['ScatolaEsterna004'],
			active: {
				petals: 'rosso',
				box: 'legno-black'
			}
		};
		this.modelSuperior.tooltip = [
			{
				selector: '.tooltip-0',
				offset: { x: 0, y: 0.045, z: 0 },
				renderOrder: Infinity
			}
		];
		this.raycasterObjects.push(this.modelSuperior);
		this.groupObjects.add(this.modelSuperior);

		// Prestige
		this.modelPrestige = this.modelPrestige.scene;
		this.modelPrestige.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});
		this.modelPrestige.position.set(2.32, 0.445, 0.66);
		this.modelPrestige.rotation.set(0, -3.96, 0);
		this.modelPrestige.scale.set(0.75, 0.75, 0.75);
		this.modelPrestige.name = 'Prestige';
		this.modelPrestige.description =
			"A symbol of devotion that speaks to the depth of feelings and the <strong>beauty of a bond</strong>.<br/><br/>Prepare to fall in love with this exquisite combination of <strong>elegance and beauty</strong>.<br/>Lift the lid and elevate your sense of beauty and style.";
		this.modelPrestige.cameraOffset = { x: -0.185, y: 0.075, z: -0.12 };
		this.modelPrestige.distanceShowingTooltip = 3;
		this.modelPrestige.configurator = {
			petals: {
				blu: {
					color: '#0200aa',
					combo: ['bianco']
				},
				nero: {
					color: '#000000',
					combo: ['bianco']
				},
				rosa: {
					color: '#f1babf',
					combo: ['nero', 'bianco']
				},
				rosso: {
					color: '#e32429',
					combo: ['nero', 'bianco']
				},
				bianco: {
					color: '#ffffff',
					combo: ['nero', 'bianco']
				},
				glitter: {
					color: '#e32429',
					combo: ['bianco']
				},
				rubino: {
					color: '#890032',
					combo: ['bianco']
				}
			},
			'petals-layers': ['Bulbi_interni001', 'Bulbi_esterni001'],
			box: {
				nero: {
					color: '#000000',
					combo: ['bianco', 'rosa', 'rosso']
				},
				bianco: {
					color: '#FFFFFF',
					combo: ['bianco', 'blu', 'nero', 'rosa', 'rosso', 'glitter', 'rubino']
				}
			},
			'box-layers': ['Scatola008'],
			active: {
				petals: 'rosso',
				box: 'nero'
			}
		};
		this.modelPrestige.tooltip = [
			{
				selector: '.tooltip-1',
				offset: { x: 0, y: 0.045, z: 0 },
				renderOrder: Infinity
			}
		];

		this.raycasterObjects.push(this.modelPrestige);
		this.groupObjects.add(this.modelPrestige);

		// Bijou
		this.modelBijou = this.modelBijou.scene;
		this.modelBijou.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});
		this.modelBijou.children?.forEach((e) => {
			this.raycasterObjects.push(e);
		});
		this.modelBijou.position.set(0.0, 0.355, -0.3);
		this.modelBijou.originPosition = this.modelBijou.position;
		this.modelBijou.rotation.set(0, -8.888, 0);
		this.modelBijou.scale.set(0.75, 0.75, 0.75);
		this.modelBijou.name = 'Bijou';
		this.modelBijou.description =
			"A <strong>small masterpiece</strong> that will surely leave a lasting impression.From the golden details to the crystalline finish, it is a true work of art.<br/><br/>A mesmerizing composition that captures the essence of <strong>luxury and refinement</strong>.<br/>A small masterpiece that will capture your heart and soul.<br/>A <strong>small treasure</strong> that will forever inspire awe and wonder.";
		this.modelBijou.cameraOffset = { x: -0.2, y: 0.1, z: 0.1 };
		this.modelBijou.distanceShowingTooltip = 1;
		this.modelBijou.configurator = {
			petals: {
				arcobaleno: { color: 'linear-gradient(90deg, rgba(156,143,224,1) 0%, rgba(159,190,65,1) 27%, rgba(222,110,10,1) 51%, rgba(220,32,109,1) 73%, rgba(13,13,89,1) 100%);', combo: null },
				unicorn: {
					color: 'linear-gradient(90deg, rgba(94,9,121,1) 0%, rgba(254,195,255,1) 48%, rgba(0,212,255,1) 100%);',
					combo: null
				},
				arancione: { color: '#f8a01d', combo: null },
				'azzurro-cielo': { color: '#a9c5de', combo: null },
				'azzurro-tiffany': { color: '#4cc0c9', combo: null },
				bianco: { color: '#ffffff', combo: null },
				blu: { color: '#0200aa', combo: null },
				gialla: { color: '#ffc80c', combo: null },
				'grigio-perla': { color: '#7a7c9b', combo: null },
				'nero-notte': { color: '#000000', combo: null },
				rosa: { color: '#f1babf', combo: null },
				'rosa-pesca': { color: '#fdc39c', combo: null },
				'rosa-shocking': { color: '#ff00bc', combo: null },
				rosso: { color: '#e32429', combo: null, default: true },
				rubino: { color: '#890032', combo: null },
				'verde-foresta': { color: '#255726', combo: null },
				'verde-mela': { color: '#cad800', combo: null },
				'verde-menta': { color: '#cceea6', combo: null },
				viola: { color: '#8e2d8f', combo: null },
				violetto: { color: '#8272e6', combo: null }
			},
			'petals-layers': ['Bulbo'],
			active: {
				petals: 'rosso'
			}
		};
		this.modelBijou.tooltip = [
			{
				selector: '.tooltip-2',
				offset: { x: 0, y: 0.045, z: 0 },
				renderOrder: Infinity
			}
		];

		this.raycasterObjects.push(this.modelBijou);
		this.groupObjects.add(this.modelBijou);

		// Petite
		this.modelPetite = this.modelPetite.scene;
		this.modelPetite.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});
		this.modelPetite.position.set(0.69, 0.459, -2.27);
		this.modelPetite.rotation.set(0, -2.54, 0);
		this.modelPetite.scale.set(0.75, 0.75, 0.75);
		this.modelPetite.name = 'Petite';
		this.modelPetite.description =
			"Lift the lid and let the enchantment begin. You will fall in love with this <strong>exquisite blend</strong> of elegance and beauty.<br/><br/>Elevate your style and indulge in the beauty of <strong>everlasting roses</strong>.<br/> Every glance at this little masterpiece is like a journey into a poetic fairy tale.";
		this.modelPetite.cameraOffset = { x: -0.25, y: 0, z: 0.15 };
		this.modelPetite.distanceShowingTooltip = 1.3;
		this.modelPetite.configurator = {
			petals: {
				rosa: {
					color: '#f1babf',
					combo: ['nero', 'bianco']
				},
				rosso: {
					color: '#fc1b40',
					combo: ['nero', 'bianco']
				}
			},
			'petals-layers': ['Bulbi_esterni018'],
			box: {
				nero: {
					color: '#000000',
					combo: ['rosa', 'rosso']
				},
				bianco: {
					color: '#FFFFFF',
					combo: ['rosa', 'rosso']
				}
			},
			'box-layers': ['ScatolaEsterna002'],
			active: {
				petals: 'rosso',
				box: 'nero'
			}
		};
		this.modelPetite.tooltip = [
			{
				selector: '.tooltip-3',
				offset: { x: 0, y: 0.045, z: 0 },
				renderOrder: Infinity
			}
		];

		this.raycasterObjects.push(this.modelPetite);
		this.groupObjects.add(this.modelPetite);

		// Cylinder glow over Models
		this.cylinderGeometry = new THREE.CylinderGeometry(1, 1, 2, 32, 1, true);

		this.cylinderMaterial = new THREE.ShaderMaterial({
			uniforms: {
				uTime: { value: 0 },
				uOpacityEnabled: { value: this.cylinderActive }
			},
			vertexShader: cylinderVertexShader,
			fragmentShader: cylinderFragmentShader,
			transparent: true,
			depthTest: true,
			//side: THREE.DoubleSide,
			blending: THREE.NormalBlending,
			depthWrite: false
		});

		this.cylinderSuperior = new THREE.Mesh(this.cylinderGeometry, this.cylinderMaterial);
		this.cylinderSuperior.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});

		this.cylinderSuperior.position.set(2.32, 0.532, 0.66);
		this.cylinderSuperior.scale.set(0.075, 0.1, 0.075);

		this.groupObjects.add(this.cylinderSuperior);

		this.cylinderPrestige = new THREE.Mesh(this.cylinderGeometry, this.cylinderMaterial);
		this.cylinderPrestige.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});

		this.cylinderPrestige.position.set(-0.938, 0.29, 0.649);
		this.cylinderPrestige.scale.set(0.093, 0.1, 0.093);

		this.groupObjects.add(this.cylinderPrestige);

		this.cylinderBijou = new THREE.Mesh(this.cylinderGeometry, this.cylinderMaterial);
		this.cylinderBijou.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});
		this.cylinderBijou.position.set(0.0, 0.445, -0.3);
		this.cylinderBijou.scale.set(0.05, 0.1, 0.05);

		this.groupObjects.add(this.cylinderBijou);

		this.cylinderPetite = new THREE.Mesh(this.cylinderGeometry, this.cylinderMaterial);
		this.cylinderPetite.traverse(function (child) {
			if (child.isMesh) {
				child.material.fog = false;
			}
		});
		this.cylinderPetite.position.set(0.69, 0.519, -2.27);
		this.cylinderPetite.scale.set(0.085, 0.1, 0.085);

		this.groupObjects.add(this.cylinderPetite);

		// Push tooltips objects
		this.tooltips.addObjects([this.modelPetite, this.modelBijou, this.modelSuperior, this.modelPrestige]);

		//Petals
		this.groupObjects.add(this.modelTwistedPetals.scene);
		this.mixerModelPetals = new AnimationMixer(this.modelTwistedPetals.scene);

		//Add Groups on scene

		this.scene.add(this.groupObjects);
		this.scene.add(this.groupIntro);

		// this.groupObjects.position.set(0, -1, 0);
		// this.groupObjects.rotation.set(-0.5, 0, 0.35);

		if (this.scene.debug) {
			// Grid
			this.grid = new THREE.GridHelper(600, 200, 0xddd, 0xddd);
			this.grid.position.set(0, 0, 0);
			this.scene.add(this.grid);

			// Axes
			this.axesHelper = new THREE.AxesHelper(10);
			this.scene.add(this.axesHelper);
		}
	}

	onResize() {
		// this.renderer.updateSize()
		// this.tooltipRenderer.setSize(
		// 	// this.container.offsetWidth,
		// 	window.innerWidth,
		// 	// this.container.offsetHeight,
		// 	window.innerHeight
		// );
	}

	introAnimation() {
		//Delay Intro

		if (!this.lowPerformance) {
			this.modelIntroPetals;
			this.modelIntroPetals.scene.position.set(-1, -0.06, 0); //SPOSTATO RISPETTO BLENDER
			//this.modelIntroPetals.scene.position.set(0, 0, 0);
			this.groupIntro.add(this.modelIntroPetals.scene);

			this.mixerIntroPetals = new AnimationMixer(this.modelIntroPetals.scene);
			if (this.modelIntroPetals.animations) {
				setTimeout(() => {
					this.modelIntroPetals.animations.forEach((e, index) => {
						this.actionIntroPetals = this.mixerIntroPetals.clipAction(this.modelIntroPetals.animations[index]);
						this.actionIntroPetals.play();
					});
					this.animatedPetalsIntro = true;
				}, this.delayIntro);
			}
			this.mixerIntroHoverPetals = new AnimationMixer(this.modelHoverPetals.scene);

			if (this.modelHoverPetals.animations) {

				setTimeout(() => {
					this.scene.add(this.modelHoverPetals.scene);
					this.modelHoverPetals.animations.forEach((e, index) => {
						if (e.name && e.name.includes("Intro")) {
							this.actionIntroPetals = this.mixerIntroHoverPetals.clipAction(this.modelHoverPetals.animations[index]);
							this.actionIntroPetals.setLoop(THREE.LoopOnce, 1);
							this.actionIntroPetals.clampWhenFinished = true;
							this.actionIntroPetals.play();  // Only play when the name includes "Intro"
						}
					});
					this.animatedPetalsIntro = true;
				}, this.delayIntro * .9);

				setTimeout(() => {

					this.introPetalsClones.forEach(group => {
						// Assicurati che ogni gruppo abbia un mixer unico
						if (!group.mixer) {
							group.mixer = new THREE.AnimationMixer(group);
						}

						this.scene.add(group);

						group.animations.forEach((animation, index) => {
							if (animation.name && animation.name.includes("Intro")) {
								const actionIntroPetals = group.mixer.clipAction(group.animations[index]);
								actionIntroPetals.setLoop(THREE.LoopOnce, 1);
								actionIntroPetals.clampWhenFinished = true;
								actionIntroPetals.play();
							}
						});
					});
				}, this.delayIntro * .6);
			}

			this.groupObjects.add(this.modelIntroPetals.scene);
		}

		setTimeout(() => {
			const value = 1; // Starting value
			const duration = 500; // Milliseconds
			const startTime = Date.now();

			const updateOpacity = () => {
				let elapsed = Date.now() - startTime;
				let t = elapsed / duration;
				if (t < 0) t = 0;
				if (t > 1) t = 1;
				// Now t is a real number in from 0.0 to 1.0

				let currentValue = (1 - t) * value; // Goes from 1000.0 to 0.0
				this.plane.material.opacity = currentValue;

				if (elapsed < duration) setTimeout(updateOpacity, 1);
			};

			updateOpacity();
		}, this.delayIntro);

		//Active scroll info
		setTimeout(() => {
			this.DOM.scrollInfo.classList.add('is-scroll-info-active');
			this.tutorialOpen = true;
		}, this.delayIntro * 3.25);

		setTimeout(() => {
			this.camera.cameraControls.setLookAt(
				this.cameraPathVector[0].x,
				this.cameraPathVector[0].y,
				this.cameraPathVector[0].z,
				this.cameraPathVectorLookAt[0].x,
				this.cameraPathVectorLookAt[0].y,
				this.cameraPathVectorLookAt[0].z,
				true
			);
		}, this.delayIntro);

		setTimeout(() => {
			this.groupIntro.scale.set(0, 0, 0);
		}, this.delayIntro + 1000);

		setTimeout(() => {
			if (this.animatedPetalsIntro) {
				this.modelIntroPetals.animations.forEach((e, index) => {
					this.actionPetalsdIntro = this.mixerModelRose.clipAction(this.modelIntroPetals.animations[index]);
					this.actionPetalsdIntro.stop();
				});
				this.animatedPetalsIntro = false;
				this.modelIntroPetals.scene.scale.set(0, 0, 0);
			}
		}, this.delayIntro + 3500);

		//close scroll info
		this.DOM.scrollInfoClose.addEventListener('click', () => {
			this.DOM.body.classList.add('is-scroll-info-closed');
			this.DOM.scrollInfo.classList.remove('is-scroll-info-active');
			this.tutorialOpen = false;
		});
	}

	findObjectLevelWithConfiguratorData(object, level) {
		if (level === 0) {
			return;
		}
		level--;

		if (Object.prototype.hasOwnProperty.call(object, 'configurator')) {
			return object;
		}

		if (Object.prototype.hasOwnProperty.call(object, 'parent')) {
			return this.findObjectLevelWithConfiguratorData(object.parent, level);
		}

		return null;
	}

	onGlobalClick(e) {

		if (this.tutorialOpen) {
			return;
		}

		// Se l'oggetto è interattivo
		this.setPointerPosition(e);

		const intersectObjectLocal = this.getIntersectObjects(this.raycasterObjects);

		if (intersectObjectLocal.length && this.cameraOnPath ) {
			this.clickedObject = this.findObjectLevelWithConfiguratorData(intersectObjectLocal[0].object, 10);
			this.objectIn();
		}

		// Se il target ha questa classe chiude l'animazione
		const hasClassOut = e.target.classList.contains('js-object-out');

		if (hasClassOut) {
			this.objectOut();
		}
	}



	objectIn() {
		this.sound3.play();
		this.cylinderActive = false;

		if (this.scene.debug) {
			if (
				this.intersectObject[0].object !== this.controls.getTransformControlsObject() &&
				this.intersectObject[0].object.drag
			) {
				this.controls.addTransformControlsObject(this.intersectObject[0].object);
			}
		}

		// if (this.cameraOnPath && !this.intersectObject[0].object.drag) {
		if (this.cameraOnPath) {
			if (!this.cameraToObjectAnimated) {
				this.DOM.body.classList.add('is-object-in-start');

				if (!this.clickedObject.cameraOffset) {
					this.clickedObject.cameraOffset = { x: 0, y: 0, z: 0 };
				}

				this.cameraToObjectAnimated = true;
				this.cameraOnPath = false;
				this.cameraPreviousPosition = {
					...this.camera.cameraControls.getPosition()
				};
				this.cameraPreviousLookAt = {
					...this.camera.cameraControls.getTarget()
				};

				this.velocitàOscillazione = 0;

				this.playRoseTwisted(this.clickedObject);

				// Camera animation
				const cameraToObject = async () => {
					await this.camera.cameraControls.setLookAt(
						this.clickedObject.position.x + this.clickedObject.cameraOffset.x,
						this.clickedObject.position.y + this.clickedObject.cameraOffset.y,
						this.clickedObject.position.z + this.clickedObject.cameraOffset.z,
						this.clickedObject.position.x,
						this.clickedObject.position.y,
						this.clickedObject.position.z,
						true
					);

					this.cameraToObjectAnimated = false;

					trigger('clickObject', {
						action: 'object-in',
						object: this.clickedObject
					});

					this.DOM.body.classList.add('is-object-in');

					this.DOM.moreInfo.addEventListener('click', (e) => {
						this.DOM.body.classList.add('is-more-info');
						//per il mobile ti apre il pannello solo se clicchi su more-info
					});
					this.DOM.moreInfoClose.addEventListener('click', (e) => {
						this.DOM.body.classList.remove('is-more-info');
						//per il mobile ti apre il pannello solo se clicchi su more-info
					});

				};

				cameraToObject();
			}
		}
	}

	objectOut() {
		this.DOM.body.classList.remove('is-object-in', 'is-active-panel', 'is-object-in-start', 'is-more-info');
		this.cylinderActive = true;

		const panel = document.querySelector('.panel');
		panel.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		this.modelTwistedPetals.animations.forEach((e, index) => {
			this.actionModelPetalsStop = this.mixerModelPetals.clipAction(this.modelTwistedPetals.animations[index]);
			this.actionModelPetalsStop.stop();
		});
		this.clickedObject = false;
		this.velocitàOscillazione = 0.75;

		if (this.cameraOnPath) {
			return;
		}

		this.sound3.play();
		// Se la camera non sta già facendo il cameraToObject allora ritorna al punto precedente sul path
		if (!this.cameraToObjectAnimated) {
			// Camera animation
			const cameraToPreviousPath = async () => {
				await this.camera.cameraControls.setLookAt(
					this.cameraPreviousPosition.x,
					this.cameraPreviousPosition.y,
					this.cameraPreviousPosition.z,
					this.cameraPreviousLookAt.x,
					this.cameraPreviousLookAt.y,
					this.cameraPreviousLookAt.z,
					true
				);

				this.cameraToObjectAnimated = false;
				this.cameraOnPath = true;
			};

			cameraToPreviousPath();
		}
		// } else {
		// 	this.DOM.body.classList.add('is-object-in');
		// }

		// Scateno l'evento di chiusura (Es. chiudere il modale)
		trigger('clickObject', {
			action: 'object-out',
			object: null
		});
	}

	addSoundControl() {
		const muted = {
			true: () => this.soundIcon.classList.add('sound-icon--muted'),
			false: () => this.soundIcon.classList.remove('sound-icon--muted')
		};

		this.soundMuted = false;
		this.soundIcon = document.querySelector('.sound-icon');
		Howler.mute(this.soundMuted);

		this.soundIcon.addEventListener('click', () => {
			this.soundMuted = !this.soundMuted;
			muted[this.soundMuted]();
			Howler.mute(this.soundMuted);
		});

		muted[this.soundMuted]();
	}

	enableCameraParallax() {
		if (this.cameraOnPath) {
			let currentPositionOnCurve = new THREE.Vector3();
			this.cameraPathCurve.getPoint(Number(this.wheelVal), currentPositionOnCurve);

			let parallaxCameraX = 0;
			let parallaxCameraY = 0;

			if (!this.wheelActive) {
				parallaxCameraX = normalize(this.mousePointer.x, -0.75, 0.75, -0.05, 0.05);
				parallaxCameraY = normalize(this.mousePointer.y, -0.75, 0.75, -0.05, 0.05);
			}

			this.camera.cameraControls.setLookAt(
				currentPositionOnCurve.x,
				currentPositionOnCurve.y,
				currentPositionOnCurve.z,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).x + parallaxCameraX,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).y + parallaxCameraY,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).z,
				true
			);
		}
	}

	cameraInteraction() {
		// 360 camera rotation
		if (!(this.cameraToObjectAnimated || this.cameraOnPath)) {
			if (this.clickedObject) {
				if (this.clickedObject.cameraInteraction === '360') {
					this.camera.cameraControls.azimuthAngle += 40 * this.clockDelta * THREE.MathUtils.DEG2RAD;
				}
			}
		}
	}

	createCameraPath() {
		this.cameraPathCurve = new THREE.CatmullRomCurve3(this.cameraPathVector);
		this.cameraPathCurve.curveType = 'catmullrom';
		this.cameraPathCurve.closed = this.pathLoop;

		this.cameraPathCurveLookAt = new THREE.CatmullRomCurve3(this.cameraPathVectorLookAt);
		this.cameraPathCurveLookAt.curveType = 'catmullrom';
		this.cameraPathCurveLookAt.closed = this.pathLoop;

		if (this.scene.debug) {
			const vectorBoxGeometry = new THREE.BoxGeometry(0.08, 0.08, 0.08);

			// Vector box
			const vectorBoxCameraMaterial = new THREE.MeshBasicMaterial({
				color: 0xff0000
			});

			for (var vector of this.cameraPathVector) {
				const vectorBox = new THREE.Mesh(vectorBoxGeometry, vectorBoxCameraMaterial);
				vectorBox.drag = true;
				vectorBox.position.copy(vector);
				this.scene.add(vectorBox);
				this.raycasterObjects.push(vectorBox);
				this.splineHelperObjects.cameraPath.push(vectorBox);
			}

			// Path line
			const cameraPathPoints = this.cameraPathCurve.getPoints(100);
			const lineGeometry = new THREE.BufferGeometry().setFromPoints(cameraPathPoints);

			const lineMaterial = new THREE.LineBasicMaterial({
				color: 0xff0000
			});
			const curveObject = new THREE.Line(lineGeometry, lineMaterial);

			this.scene.add(curveObject);

			const cameraPathLookAtPoints = this.cameraPathCurveLookAt.getPoints(100);
			const lineGeometryLookat = new THREE.BufferGeometry().setFromPoints(cameraPathLookAtPoints);
			const materialLookat = new THREE.LineDashedMaterial({
				color: 0x0000ff,
				dashSize: 0.1,
				gapSize: 0.08
			});
			const curveObjectLookat = new THREE.Line(lineGeometryLookat, materialLookat);
			curveObjectLookat.computeLineDistances();
			this.scene.add(curveObjectLookat);

			const sphereMaterialLookat = new THREE.MeshBasicMaterial({
				color: 0x0000ff
			});

			for (const pointLookat of this.cameraPathVectorLookAt) {
				const helperLookat = new THREE.Mesh(vectorBoxGeometry, sphereMaterialLookat);
				helperLookat.drag = true;
				helperLookat.position.copy(pointLookat);
				this.scene.add(helperLookat);
				this.raycasterObjects.push(helperLookat);
				this.splineHelperObjects.cameraPathLookAt.push(helperLookat);
			}

			this.controls.transformControls.addEventListener('objectChange', () => {
				//this.updateSplineOutline(curveObject);
			});
		}
	}

	exportSpline(pathVector) {
		let strplace = [];

		if (pathVector === 'cameraPathVector') {
			for (let i = 0; i < this.cameraPathVector.length; i++) {
				const p = this.splineHelperObjects.cameraPath[i].position;
				strplace.push(`new THREE.Vector3(${p.x}, ${p.y}, ${p.z})`);
			}
		} else {
			for (let i = 0; i < this.cameraPathVectorLookAt.length; i++) {
				const p = this.splineHelperObjects.cameraPathLookAt[i].position;
				strplace.push(`new THREE.Vector3(${p.x}, ${p.y}, ${p.z})`);
			}
		}

		const code = `[${strplace.join(',\n\t')}]`;
		prompt('copy and paste code', code);
	}

	updateSplineOutline(curveObject) {
		// cameraPathCurve = CatmullRomCurve3 Invisibile
		// curveObject = THREE.Line(lineGeometry, lineMaterial) Colorata

		const splineMesh = curveObject;
		const position = splineMesh.geometry.attributes.position;

		for (let i = 0; i < this.cameraPathCurve.arcLengthDivisions; i++) {
			const t = i / (this.cameraPathCurve.arcLengthDivisions - 1);
			this.cameraPathCurve.getPoint(t, this.pathPoint);
			position.setXYZ(i, this.pathPoint.x, this.pathPoint.y, this.pathPoint.z);
			//if (i < 2) {
			//console.log('positionUpdated', this.position);
			//console.log('pathPoint', this.pathPoint);
			//}
		}

		position.needsUpdate = true;
	}

	interpolate(currentValue, targetValue, speed) {
		if (Math.abs(targetValue - currentValue) < 0.01) {
			return targetValue;
		}
		return currentValue + (targetValue - currentValue) * speed;
	}

	onWheel(e) {
		let deltaY;

		if (!this.lowPerformance) {
			let targetFogDensity;
			if (this.wheelVal == 0 && this.wheelVal <= 0.12) {
				targetFogDensity = 0.3;
			} else if (this.wheelVal >= 0.12 && this.wheelVal <= 0.45) {
				this.modelEnvironment.scene.getObjectByName('PadigilioneA').material.fog = false;
				targetFogDensity = 0.8;
			} else if (this.wheelVal >= 0.45 && this.wheelVal <= 0.905) {
				this.modelEnvironment.scene.getObjectByName('PadigilioneA').material.fog = false;
				targetFogDensity = 0.65;
			} else {
				targetFogDensity = 0.3;
			}
			this.scene.fog.density = this.interpolate(this.scene.fog.density, targetFogDensity, 0.05);
		}

		if (e) {
			if (e.deltaY) {
				deltaY = e.deltaY / 50000;
			} else {
				deltaY = 0;
			}

			if (e.touches) {
				deltaY = this.touchDeltaY / 20000;
			}

		}

		this.wheelActive = true;

		if (this.wheelTimer !== null) {
			clearTimeout(this.wheelTimer);
		}
		this.wheelTimer = setTimeout(() => {
			this.wheelActive = false;
		}, 50);

		if (this.cameraOnPath && !this.showAllProducts && !this.tutorialOpen) {
			if (!this.clickScrollTo) {
				this.wheelVal += deltaY;
			}

			this.sky.update(this.wheelVal);

			if (!this.lowPerformance) {
				this.DOM.scrollImage.forEach((elem) => {
					elem.classList.remove('is-scroll-product-active');
				});

				this.DOM.scrollBar.forEach((elem, index) => {
					const minScroll = parseFloat(index === 0 ? '0' : this.objectsScrollPosition[index - 1]);
					const maxScroll = parseFloat(elem.dataset.scrollTo);

					if (this.wheelVal < maxScroll) {
						elem.children[0].style.width = '0%';
					}

					if (this.wheelVal > minScroll) {
						if (elem.previousElementSibling) {
							elem.previousElementSibling.children[0].classList.add('is-scroll-product-active');
						}
						elem.children[0].style.width = '100%';
					}

					if (this.wheelVal >= minScroll && this.wheelVal <= maxScroll) {
						const scrollWidth = normalize(this.wheelVal, minScroll, maxScroll, 0, 100);
						elem.children[0].style.width = `${Math.round(scrollWidth)}%`;
					}
				});
			}

			const isActive = this.wheelVal > 0.035;
			this.DOM.scrollContainer.classList.toggle('is-scroll-container-active', isActive);

			if (this.wheelVal <= 0) {
				this.wheelVal = 0;
			}

			if (this.wheelVal > 1) {
				if (this.pathLoop) {
					this.wheelVal = this.wheelVal % 1;
				} else {
					this.wheelVal = 1;
				}
			}

			const currentPositionOnCurve = new THREE.Vector3();
			this.cameraPathCurve.getPoint(Number(this.wheelVal), currentPositionOnCurve);

			this.camera.cameraControls.setLookAt(
				currentPositionOnCurve.x,
				currentPositionOnCurve.y,
				currentPositionOnCurve.z,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).x,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).y,
				this.cameraPathCurveLookAt.getPointAt(Number(this.wheelVal)).z,
				true
			);
		}
	}

	onObjectHover() {

		if ( this.tutorialOpen) {
			return;
		}

		if (this.intersectObject.length && this.cameraOnPath) {
			if (!this.intersectObjectHover) {
				this.sound1.play();
			}

			if (this.previousIntersectObject.length) {
				if (this.intersectObject[0].object.name !== this.previousIntersectObject[0].object.name) {
					this.intersectObjectHover = false;
					document.body.classList.remove('cursor-pointer');
				} else {
					this.intersectObjectHover = true;
					document.body.classList.add('cursor-pointer');
				}
			} else {
				this.intersectObjectHover = false;
				document.body.classList.remove('cursor-pointer');
			}
		} else {
			document.body.classList.remove('cursor-pointer');
		}

		this.previousIntersectObject = this.intersectObject;
	}

	onPetalsHover() {
		if (this.intersectPetals.length && this.cameraOnPath) {
			const petal = this.intersectPetals[0].object;

			if (!this.intersectPetalsHover) {
				let mixer = this.petalsMixers.get(petal.parent.uuid || petal.uuid);

				if (!mixer) {
					mixer = new THREE.AnimationMixer(petal.parent || petal);
					this.petalsMixers.set(petal.parent.uuid || petal.uuid, mixer);
				}

				const animation = petal.parent.animations ? petal.parent.animations[0] : petal.animations[0];

				if (animation) {
					const action = mixer.clipAction(animation);
					action.setLoop(THREE.LoopOnce, 1);
					action.clampWhenFinished = true;
					action.play();
				}
			}

			this.intersectPetalsHover = this.previousIntersectPetals.length &&
				this.intersectPetals[0].object.name === this.previousIntersectPetals[0].object.name;
		}

		this.previousIntersectPetals = this.intersectPetals;
	}


	onPointerMove(e) {
		if (!this.cameraOnPath) return;

		// Recupera l'oggetto intersecato nella posizione del mouse
		this.intersectObject = this.getIntersectObjects(this.raycasterObjects);
		//TODO: Toglierlo per i device con touch
		this.intersectPetals = this.getIntersectObjects(this.raycasterPetals);

		// Stop any other event handler from firing (such as the mouse's TrackballControls)
		// event.preventDefault();

		this.setPointerPosition(e);

		this.enableCameraParallax();
	}

	setPointerPosition(e) {
		this.mousePointer.x = (e.clientX / window.innerWidth) * 2 - 1;
		this.mousePointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
	}

	getIntersectObjects(raycasterObj, recursive) {
		if (!raycasterObj) return

		this.raycaster.setFromCamera(
			this.mousePointer,
			this.debug ? this.controls.getCamera() : this.camera.threeCamera
		);
		this.intersects = this.raycaster.intersectObjects(raycasterObj, recursive ? recursive : true);
		return this.intersects;
	}

	updateWorld() {
		this.cameraInteraction();
	}

	setStats(active) {

		this.stats = new Stats(active);

		if (active) {
			let guiObj = {
				'Export cameraPath': () => {
					this.exportSpline('cameraPathVector');
				},
				'Export cameraPathLookAt': () => {
					this.exportSpline('cameraPathVectorLookAt');
				}
			};

			this.gui = new GUI();
			/*this.gui
				.add(this.firefliesMaterial.uniforms.uSize, 'value')
				.min(0)
				.max(500)
				.step(1)
				.name('firefliesSize');*/
			this.gui.add(guiObj, 'Export cameraPath');
			this.gui.add(guiObj, 'Export cameraPathLookAt');
		}
	}

	animate() {
		// Stats begin
		this.stats.begin();

		// Delta time is sometimes needed for certain updates
		this.clockDelta = this.clock.getDelta();
		this.elapsedTime = this.clock.getElapsedTime();

		// Click scroll to
		if (this.clickScrollTo) {
			this.onWheel();

			if (this.clickScrollDirection === 'positive') {
				if (this.wheelVal <= this.clickScrollToTarget) {
					this.wheelVal = this.wheelVal += 1 / (this.scrollToAnimationTime * 60);
				} else {
					this.clickScrollTo = false;
				}
			} else {
				if (this.wheelVal >= this.clickScrollToTarget) {
					this.wheelVal = this.wheelVal -= 1 / (this.scrollToAnimationTime * 60);
				} else {
					this.clickScrollTo = false;
				}
			}
		}

		// Tooltips
		this.tooltips.update();

		// Modelli che oscillano
		// TODO: Far partire le oscillazioni quando ha finito l'intro
		this.oscillationTime = this.elapsedTime; // Rende l'oscillationTime ciclico

		const oscillationFactor = Math.sin(this.oscillationTime * this.velocitàOscillazione);

		// Aggiorna la posizione verticale del modello
		this.modelSuperior.position.y += 0.00015 * oscillationFactor;

		// Aggiorna la rotazione del modello
		this.modelSuperior.rotation.y = ((oscillationFactor * Math.PI) / 2) * 0.5;


		this.modelRose.position.y =
			this.modelRose.position.y + 0.00135 * Math.sin(this.oscillationTime * this.velocitàOscillazione);
		this.modelRose.rotation.y = Math.sin(this.oscillationTime * this.velocitàOscillazione) * Math.PI * 0.5;
		this.modelRose.rotation.x =
			((Math.sin(this.oscillationTime * this.velocitàOscillazione) * Math.PI) / 2) * 0.25;

		this.modelRose1.position.y =
			this.modelRose1.position.y + 0.0001 * Math.sin(this.oscillationTime * this.velocitàOscillazione);
		this.modelRose1.rotation.y =
			((Math.sin(this.oscillationTime * this.velocitàOscillazione) * Math.PI) / 1.5) * 0.5;
		this.modelRose1.rotation.x =
			((Math.sin(this.oscillationTime * this.velocitàOscillazione) * Math.PI) / 2.5) * 0.25;

		this.modelRose2.position.y =
			this.modelRose2.position.y + 0.0015 * Math.sin(this.oscillationTime * this.velocitàOscillazione);
		this.modelRose2.rotation.y =
			((Math.sin(this.oscillationTime * this.velocitàOscillazione) * Math.PI) / 2) * 0.4;

		// Reimposta oscillationTime dopo un certo periodo di tempo (ad esempio, 10 secondi) per evitare l'accumulo di errori.
		const resetTime = 10000; // 10 secondi in millisecondi
		if (this.oscillationTime > resetTime) {
			this.oscillationTime = 0;
		}

		//Hover
		this.onObjectHover();

		//Hover Petals
		if (!this.lowPerformance) {
			this.onPetalsHover();
		}

		// Camera controls
		this.hasControlsUpdated = this.camera.cameraControls.update(this.clockDelta);

		//Cylinder
		this.cylinderMaterial.uniforms.uTime.value = this.elapsedTime;

		//Plants
		this.DracaenaMaterial.uniforms.u_windTime.value +=
			this.DracaenaMaterial.uniforms.u_windSpeed.value * this.clockDelta;
		this.DracaenaMaterial.uniformsNeedUpdate = true;

		this.MonstreraMaterial.uniforms.u_windTime.value +=
			this.MonstreraMaterial.uniforms.u_windSpeed.value * this.clockDelta;
		this.MonstreraMaterial.uniformsNeedUpdate = true;

		//Particles
		this.gu.time.value = this.elapsedTime;

		//Env.Sphere
		this.envSphereMaterial.uniforms.time.value = this.elapsedTime / 6;
		this.envSphereMaterial.uniforms.morph.value = 0;
		this.envSphereMaterial.uniforms.dnoise.value = 1;

		this.envSphereMaterial.uniforms['RGBr'].value = 0 / 10;
		this.envSphereMaterial.uniforms['RGBg'].value = 2 / 10;
		this.envSphereMaterial.uniforms['RGBb'].value = 4 / 10;
		this.envSphereMaterial.uniforms['RGBn'].value = 20 / 100;
		this.envSphereMaterial.uniforms['RGBm'].value = 0;
		this.envSphereMaterial.uniforms['psize'].value = 8;

		this.renderer.render(this.scene, this.debug ? this.controls.getCamera() : this.camera.threeCamera);

		// Update orbit controls
		if (this.scene.debug) {
			this.controls.update();
		}

		// Gltf animation
		if (this.mixerModelRose) {
			this.mixerModelRose.update(this.clockDelta);
		}
		if (this.mixerModelPetals) {
			this.mixerModelPetals.update(this.clockDelta);
		}
		if (this.mixerIntroPetals) {
			this.mixerIntroPetals.update(this.clockDelta);
		}
		if (this.mixerIntroHoverPetals) {
			this.mixerIntroHoverPetals.update(this.clockDelta);
		}

		if (this.introPetalsClones) {
			this.introPetalsClones.forEach(group => {
				if (group.mixer) {
					group.mixer.update(this.clockDelta);
				}
			});
		}

		if (this.petalsMixers) {
			this.petalsMixers.forEach((mixer, uuid) => {
				// Aggiorna il mixer
				mixer.update(this.clockDelta);

				// Ottieni tutte le azioni dall'AnimationMixer
				const actions = mixer.getRoot().animations.map(animation => mixer.existingAction(animation));

				// Verifica se tutte le azioni sono finite
				const allActionsFinished = actions.every(action => action.paused || action.enabled === false);

				if (allActionsFinished) {
					// Rimuovi il mixer dalla mappa se tutte le azioni sono finite
					this.petalsMixers.delete(uuid);
				}
			});

		}

		//this.water.update();
		//this.postProcessing.updateFilmGrain();
		this.updateWorld();

		// Stats end
		this.stats.end();

		// RAF
		requestAnimationFrame(this.animate.bind(this)); // Bind the main class instead of window object
		//requestAnimationFrame(this.animate);
	}
}
