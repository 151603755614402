import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { gsap } from 'gsap';

CameraControls.install({ THREE: THREE });

// Class that creates and updates the main camera
export default class Camera {
	constructor(renderer, scene, position, lookAt) {
		this.renderer = renderer;
		this.scene = scene;
		this.position = position;
		this.lookAt = lookAt;
		// this.width = this.renderer.domElement.width;
		this.width = window.innerWidth;
		// this.height = this.renderer.domElement.height;
		this.height = window.innerHeight;

		this.init();

		// Initial sizing
		this.updateSize(renderer);

		// Listeners
		window.addEventListener('resize', () => this.updateSize(renderer), false);

		if (this.scene.debug) {
			this.debug();
		}
	}

	init() {
		// Create and position a Perspective Camera
		this.threeCamera = new THREE.PerspectiveCamera(
			68,
			this.width / this.height,
			0.1,
			1000,
		);

		// Camera controls
		this.cameraControls = new CameraControls(
			this.threeCamera,
			this.renderer.domElement,
		);
		this.cameraControls.enabled = false;

		if (this.position && this.lookAt) {
			this.cameraControls.setLookAt(
				this.position.x,
				this.position.y,
				this.position.z,
				this.lookAt.x,
				this.lookAt.y,
				this.lookAt.z,
			);
		}
	}

	debug() {
		const cameraHelper = new THREE.CameraHelper(this.threeCamera);
		this.scene.add(cameraHelper);
	}

	updateSize(renderer) {
		// Update camera aspect ratio with window aspect ratio
		this.threeCamera.aspect =
			renderer.domElement.width / renderer.domElement.height;
		// this.cameraControls._camera.aspect =
		// 	renderer.domElement.width / renderer.domElement.height;
		// Always call updateProjectionMatrix on camera change
		this.threeCamera.updateProjectionMatrix();
	}
}
